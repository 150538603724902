import socoLogo from "../assets/socoLogo.png";
import { AiOutlineLogin } from "react-icons/ai";
import React from "react";

function StatusBar() {
  const [grey, setGrey] = React.useState(false);
  
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrollHeight = window.scrollY || 0
      const whyh = document.getElementById("why")?.offsetTop || 1000;
      const guideh = document.getElementById("guide")?.offsetTop || 2000;
      setGrey(scrollHeight > whyh && scrollHeight < guideh)
    });
  }, []);

  const backgroundColor = grey ? "bg-SocoBlack" : "bg-SocoWhite"
  const textColor = grey ? "text-SocoWhite" : "text-SocoBlack"

  return (
    <div
      className={`fixed ${backgroundColor} w-4/5 xl:w-2/3 h-14 mt-5 flex rounded-full justify-between items-center ${textColor}`}
    >
      <img src={socoLogo} alt="Soco Logo" className="h-10 mx-5" />
      <div className="hidden sm:flex">
        <a
          href="#"
          className="ml-5 mr-5 text-inherit hover:text-SocoOrangeLight"
        >
          Home
        </a>
        {/* <a
          href="/projects"
          className="ml-5 mr-5 text-inherit hover:text-SocoOrangeLight"
        >
          Projects
        </a> */}
        <a
          href="#contact"
          className="ml-5 mr-5 text-inherit hover:text-SocoOrangeLight"
        >
          Contact
        </a>
      </div>
      <div
        style={{
          marginRight: "1.2rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
        className="mr-1 w-[124px]"
      >
        <a href="#contact" className="text-inherit hover:text-SocoOrangeLight">
          <AiOutlineLogin />
        </a>
      </div>
    </div>
  );
}

export default StatusBar;
