import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../elements/Layout";
import {
  AccentDiv,
  BorderDiv,
  Label,
  SubHeader,
  SubmitButton,
  Text,
} from "../customComponents";
import ClauseCard from "../cards/ClauseCard";
import BigTextInput from "../inputs/BigTextInput";
import TextInput from "../inputs/TextInput";
import { charLimitValidator } from "../utils/validators";

const formatTitle = (title) => title.replace(/\s+/g, "-").toLowerCase();

export default ({ cloudUtils }) => {
  const { profileId, clauseId } = useParams();

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: {},
    theme: "dark",
  });

  const [clauses, setClauses] = useState([]);
  const [edit, setEdit] = useState({});
  const [clauseTitle, setClauseTitle] = useState();
  const [clauseHelp, setClauseHelp] = useState();
  const [clauseText, setClauseText] = useState();

  let [active, setActive] = useState({});

  let [form] = Form.useForm();
  const [root, setRoot] = useState();
  const navigate = useNavigate();

  const goBack = () => navigate(`/${profileId}/clauses`);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocumentsBy("clauses", "root", clauseId, setClauses);
    cloudUtils.getDocument("clauses", clauseId, setRoot);
  }, [profileId]);

  return (
    <Layout title={root?.title} onBack={goBack} theme={profile.theme}>
      <Form align="left" layout="vertical" form={form} requiredMark={false}>
        <Row gutter={[0, 24]}>
          {root?.text && (
            <BorderDiv
              style={{
                width: "100%",
                padding: "16px",
                margin: "16px 0 12px",
              }}
            >
              <Row>
                <Col align="left">
                  <SubHeader style={{ paddingRight: 0 }}>
                    Section Legal Text
                  </SubHeader>
                </Col>
                <Text>{root.text}</Text>
              </Row>
            </BorderDiv>
          )}
          {clauses.length
            ? clauses
                .sort((a, b) => {
                  if (a.order < b.order) return -1;
                  if (a.order > b.order) return 1;
                  return 0;
                })
                .map(
                  ({
                    id,
                    title,
                    text,
                    help,
                    recommendEdit,
                    default: included,
                  }) => {
                    return (
                      <ClauseCard
                        title={title}
                        highlight={recommendEdit}
                        desc={
                          help ? (
                            <>
                              <Label>Summary</Label>
                              <Text>{help}</Text>
                              <br />
                              {edit[id] ? (
                                <BigTextInput
                                  displayText="Clause"
                                  label={id}
                                  initialValue={text}
                                />
                              ) : (
                                <>
                                  <Label>Clause</Label>
                                  <Text>{form.getFieldValue(id) || text}</Text>
                                </>
                              )}
                            </>
                          ) : (
                            <Text>{text}</Text>
                          )
                        }
                        onClick={
                          text
                            ? () =>
                                setEdit((prev) => {
                                  if (prev[id]) {
                                    return cloudUtils
                                      .updateDocument(
                                        "clauses",
                                        id,
                                        {
                                          text: form.getFieldValue(id),
                                          recommendEdit: false,
                                        },
                                        "UPDATE"
                                      )
                                      .then(() => {
                                        return {
                                          ...prev,
                                          [id]: !Boolean(prev[id]),
                                        };
                                      });
                                  } else {
                                    return {
                                      ...prev,
                                      [id]: !Boolean(prev[id]),
                                    };
                                  }
                                })
                            : () => navigate(`/${profileId}/clauses/${id}`)
                        }
                        action={text ? (edit[id] ? "Set" : "Edit") : "View"}
                        key={"clause" + id}
                        setActive={(checked) =>
                          setActive((prev) => {
                            prev[id] = checked;
                            cloudUtils.updateDocument(
                              "clauses",
                              id,
                              { default: checked },
                              "UPDATE"
                            );
                            return { ...prev };
                          })
                        }
                        active={id in active ? active[id] : included}
                        profile={profile}
                      />
                    );
                  }
                )
            : !root?.text && (
                <Text>
                  Create clauses here! As you gain experience, add protections
                  to your service agreement. Follow Social Contracts social
                  media or get in touch with us for tips and tricks for your
                  industry.
                </Text>
              )}
          <AccentDiv
            style={{
              width: "100%",
              padding: "16px",
              margin: "16px 0 12px",
            }}
          >
            <Row>
              <Col align="left">
                <SubHeader style={{ paddingRight: 0 }}>Add a Clause</SubHeader>
              </Col>
            </Row>

            <TextInput
              required
              displayText="Clause Title"
              label="clauseTitle"
              validators={[charLimitValidator("Clause Title", clauseTitle, 40)]}
              onChange={(e) => {
                setClauseTitle(e.target.value);
              }}
            />

            <BigTextInput
              required
              displayText="Summary"
              label="clauseHelp"
              lines={2}
              validators={[charLimitValidator("Clause Help", clauseHelp, 256)]}
              onChange={(e) => {
                setClauseHelp(e.target.value);
              }}
            />

            <BigTextInput
              required
              displayText="Legal Text"
              label="clauseText"
              onChange={(e) => {
                setClauseText(e.target.value);
              }}
            />

            <SubmitButton
              block
              onClick={() => {
                cloudUtils
                  .updateDocument(
                    "clauses",
                    `${formatTitle(clauseTitle)}-${clauseId}`,
                    {
                      title: clauseTitle,
                      help: clauseHelp,
                      root: clauseId,
                      text: clauseText,
                      profile: profileId,
                    },
                    "CREATE"
                  )
                  .then(() => {
                    window.location.reload();
                  });
              }}
            >
              Create Clause
            </SubmitButton>
          </AccentDiv>
        </Row>
      </Form>
    </Layout>
  );
};
