import { Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { BorderDiv } from "../customComponents";

function titleCase(string) {
  let sentence = string.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence;
}

export default ({ current }) => {
  const navigate = useNavigate();
  let { profileId } = useParams();

  let options = {
    preview: "",
    settings: "profile-settings",
    services: "services",
    projects: "projects",
    clauses: "clauses",
  };

  let links = Object.entries(options).filter(([key, _]) => key != current);

  return (
    <Row style={{ zIndex: 100, marginTop: 12, "justify-content": "center" }} gutter={[12, 12]}>
      {links.map(([title, link]) => (
        <Col onClick={() => navigate(`/${profileId}/${link}`)} >
          <BorderDiv
            style={{
              padding: "8px 16px",
              minWidth: '10rem',
              "border-radius": 12,
              margin: "0 5px",
            }}
          >
            {titleCase(title)}
          </BorderDiv>
        </Col>
      ))}
    </Row>
  );
};
