import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../elements/Layout";
import {
  AccentDiv,
  BigButton,
  Label,
  SubHeader,
  SubmitButton,
  Text,
} from "../customComponents";
import ClauseCard from "../cards/ClauseCard";
import TextInput from "../inputs/TextInput";
import { charLimitValidator } from "../utils/validators";
import BigTextInput from "../inputs/BigTextInput";

export default ({ cloudUtils }) => {
  const { profileId } = useParams();

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: {},
    theme: "dark",
  });

  const [clauses, setClauses] = useState([]);
  const [sectionTitle, setSectionTitle] = useState();
  const [sectionHelp, setSectionHelp] = useState();
  const [sectionText, setSectionText] = useState();
  let [active, setActive] = useState({});

  const navigate = useNavigate();
  let [form] = Form.useForm();

  const goBack = () =>
    navigate(`/${profileId}` + (profile.demo ? "/projects" : ""));

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocumentsBy("clauses", "profile", profileId, setClauses);
  }, [profileId]);

  const formatTitle = (title) => title.replace(/\s+/g, "-").toLowerCase();

  const attention = clauses.filter(({ recommendEdit }) => recommendEdit).length;
  return (
    <Layout title="Contract Clauses" onBack={goBack} theme={profile.theme}>
      {attention > 0 ? (
        <>
          <SubHeader style={{ paddingRight: 0, marginBottom: 0 }}>
            {attention} clauses need attention!
          </SubHeader>
          <Text>
            Go through the clauses of your service agreement and make sure
            they're all correct!
          </Text>
          <br />
        </>
      ) : profile.demo ? (
        <BigButton onClick={() => navigate(`/${profileId}/projects`)}>
          Almost done!
        </BigButton>
      ) : null}

      <Form align="left" layout="vertical" form={form} requiredMark={false}>
        <Row gutter={[0, 24]}>
          {clauses
            .filter((clause) => clause.section)
            .sort((a, b) => {
              if (a.order < b.order) return -1;
              if (a.order > b.order) return 1;
              return 0;
            })
            .map(({ id, title, order, help, default: included }) => {
              let subclauses = clauses
                .filter((clause) => clause.root == id)
                .map(({ title }) => title);
              return (
                <ClauseCard
                  title={order}
                  subtitle={title}
                  highlight={
                    clauses.filter(
                      ({ recommendEdit, root }) => recommendEdit && root == id
                    ).length > 0
                  }
                  desc={
                    <>
                      <Label>Description</Label>
                      <Text>{help}</Text>
                      <br />
                      {subclauses.length > 0 ? (
                        <>
                          <Label>Subclauses</Label>
                          <Text>{subclauses.join(", ")}</Text>
                          <br />
                        </>
                      ) : null}
                    </>
                  }
                  onClick={() => navigate(`/${profileId}/clauses/${id}`)}
                  action={"View"}
                  setActive={
                    ![
                      `definitions-${profileId}`,
                      `project-order-${profileId}`,
                    ].includes(id)
                      ? (checked) =>
                          setActive((prev) => {
                            prev[id] = checked;
                            cloudUtils.updateDocument(
                              "clauses",
                              id,
                              { default: checked },
                              "UPDATE"
                            );
                            return { ...prev };
                          })
                      : null
                  }
                  active={id in active ? active[id] : included}
                  key={"clause" + id}
                  profile={profile}
                />
              );
            })}
          <AccentDiv
            style={{
              width: "100%",
              padding: "16px",
              margin: "16px 0 12px",
            }}
          >
            <Row>
              <Col align="left">
                <SubHeader style={{ paddingRight: 0 }}>Add a Section</SubHeader>
              </Col>
            </Row>

            <TextInput
              required
              displayText="Section Title"
              label="sectionTitle"
              validators={[
                charLimitValidator("Section Title", sectionTitle, 40),
              ]}
              onChange={(e) => {
                setSectionTitle(e.target.value);
              }}
            />

            <BigTextInput
              required
              displayText="Description"
              label="sectionHelp"
              lines={2}
              validators={[
                charLimitValidator("Section Title", sectionTitle, 256),
              ]}
              onChange={(e) => {
                setSectionHelp(e.target.value);
              }}
            />

            <BigTextInput
              required
              displayText="Legal Text (Optional)"
              label="sectionText"
              onChange={(e) => {
                setSectionText(e.target.value);
              }}
            />

            <SubmitButton
              block
              onClick={() => {
                cloudUtils
                  .updateDocument(
                    "clauses",
                    `${formatTitle(sectionTitle)}-${profileId}`,
                    {
                      title: sectionTitle,
                      section: true,
                      help: sectionHelp,
                      text: sectionText,
                      order:
                        clauses.filter(({ section }) => section).length + 1,
                      profile: profileId,
                    },
                    "CREATE"
                  )
                  .then(() => {
                    window.location.reload();
                  });
              }}
            >
              Create Section
            </SubmitButton>
          </AccentDiv>
        </Row>
      </Form>
    </Layout>
  );
};
