import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

function Why() {
  return (
    <div
      id="why"
      className="flex flex-col relative justify-center items-center w-4/5 xl:w-2/3 pt-10"
    >
      {/* Text Araa */}
      <div className=" flex flex-col justify-center items-center mt-20 lg:mt-0">
        <h1 className="text-5xl  font-bold my-5 px-2 mb-10 text-center">
          Your mentors have told you...
        </h1>
        <p className="text-2xl  my-5 p-4 rounded-lg self-start sm:max-w-[70%] flex">
          <span className="text-SocoOrangeLight text-4xl mr-4">
            <FaQuoteLeft />
          </span>
          Use value-based pricing! A scalable business doesn't charge for its
          time.
          <span className="text-SocoOrangeLight text-4xl ml-4">
            <FaQuoteRight />
          </span>
        </p>
        <p className="text-2xl  my-5 p-4 rounded-lg self-end sm:max-w-[70%] flex">
          <span className="text-SocoOrangeLight text-4xl mr-4">
            <FaQuoteLeft />
          </span>
          Use discovery calls to vet your clients and determine if they're a
          good fit.
          <span className="text-SocoOrangeLight text-4xl  ml-4">
            <FaQuoteRight />
          </span>
        </p>
        <p className="text-2xl  my-5 p-4 rounded-lg self-start sm:max-w-[70%] flex">
          <span className="text-SocoOrangeLight text-4xl mr-4">
            <FaQuoteLeft />
          </span>
          Track your revisions! Scope creep kills and undervalues your time.
          <span className="text-SocoOrangeLight text-4xl  ml-4">
            <FaQuoteRight />
          </span>
        </p>
        <p className="text-2xl  my-5 p-4 rounded-lg self-end sm:max-w-[70%] flex">
          <span className="text-SocoOrangeLight text-4xl mr-4">
            <FaQuoteLeft />
          </span>
          Always have a signed agreement! You won't need it until it's too late.
          <span className="text-SocoOrangeLight text-4xl  ml-4">
            <FaQuoteRight />
          </span>
        </p>
      </div>
      <div>
        <div className="border-2 border-SocoOrangeLight rounded-lg mt-10 p-5">
          <p className="text-xl  text-center">
            But, how do you apply all of these lessons to your business? With
            {/* <span className="text-SocoOrangeLight font-semibold"> */}
            {` Social Contracts`}
            {/* </span> */}, you don't have to. We've worked with
            <span className="text-SocoOrangeLight font-semibold">
              {` veteran creative freelancers `}
            </span>
            and distilled their best practices into an
            <span className="text-SocoOrangeLight font-semibold">
              {` easy to use tool.`}
            </span>
          </p>
          <p className="text-2xl text-SocoOrangeLight font-bold my-5 px-2 text-center">
            And the best part? SoCo is free, forever.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Why;
