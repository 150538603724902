import React from "react";
import GuideSteps from "../Components/GuideSteps.tsx";

function How() {
  return (
    <div
      id="guide"
      style={{}}
      className="relative mb-40 overflow-hidden xl:flex xl:flex-col xl:justify-center xl:items-center w-screen"
    >
      <div className="bg-SocoOrangeLight">
        <h1 className="text-5xl text-left text-SocoBlack overflowtext font-bold px-10 w-screen m-0 py-5">
          Intake &#8594; Discovery &#8594; Contract &#8594; Payment &#8594;
          Review &#8594; Intake &#8594; Discovery &#8594; Contract &#8594;
          Payment &#8594; Review &#8594; Intake &#8594; Discovery &#8594;
          Contract &#8594; Payment &#8594; Review &#8594; Intake &#8594;
          Discovery &#8594; Contract &#8594; Payment &#8594; Review &#8594;
          Intake &#8594; Discovery &#8594; Contract &#8594; Payment &#8594;
          Review &#8594; Intake &#8594; Discovery &#8594; Contract &#8594;
          Payment &#8594; Review &#8594; Intake &#8594; Discovery &#8594;
          Contract &#8594; Payment &#8594; Review &#8594; Intake &#8594;
          Discovery &#8594; Contract &#8594; Payment &#8594; Review &#8594;
          Intake &#8594; Discovery &#8594; Contract &#8594; Payment &#8594;
          Review &#8594; Intake &#8594; Discovery &#8594; Contract &#8594;
          Payment &#8594; Review &#8594; Intake &#8594; Discovery &#8594;
          Contract &#8594; Payment &#8594; Review &#8594; Intake &#8594;
          Discovery &#8594; Contract &#8594; Payment &#8594; Review &#8594;
          Intake &#8594; Discovery &#8594; Contract &#8594; Payment &#8594;
          Review &#8594; Intake &#8594; Discovery &#8594; Contract &#8594;
          Payment &#8594; Review &#8594; Intake &#8594; Discovery &#8594;
          Contract &#8594; Payment &#8594; Review &#8594; Intake &#8594;
          Discovery &#8594; Contract &#8594; Payment &#8594; Review &#8594;
          Intake &#8594; Discovery &#8594; Contract &#8594; Payment &#8594;
          Review &#8594; Intake &#8594; Discovery &#8594; Contract &#8594;
          Payment &#8594; Review &#8594; Intake &#8594; Discovery &#8594;
          Contract &#8594; Payment &#8594; Review &#8594; Intake &#8594;
          Discovery &#8594; Contract &#8594; Payment &#8594; Review &#8594;
        </h1>
      </div>
      {/* <h1 id="bracket" className="text-4xl text-left text-SocoOrangeLight overflows w-max px-10" style={{letterSpacing: -5, marginTop: -16, marginBottom: -10}}>
        |____________________________________________________________________________________________________________________________|
      </h1>
      <h1 className="text-4xl text-SocoOrangeLight text-center">
        |
      </h1> */}
      <br/>
      <h1 className="text-2xl text-SocoOrangeLight font-bold text-center">
        Automated by SoCo
      </h1>
    </div>
  );
}

export default How;
