import React from "react";

interface GuideTextProps {
  index: number;
  className: string;
}
function GuideText(props: GuideTextProps) {
  interface details {
    title: string;
    text: React.ReactNode;
  }

  const guideDetails: details[] = [
    {
      title: "Book New Clients",
      text: (
        <p className="text-xl">
          Share your SoCo profile with clients to start generating qualified
          leads. Add links to your website, portfolio and socials to enhance
          your profile.
        </p>
      ),
    },
    {
      title: "Client Discovery",
      text: (
        <p className="text-xl">
          Ensure sure your contracts are both safe and profitable by adding your
          costs and contract clauses to your SoCo profile, and establish a
          strong agreement during discovery.
        </p>
      ),
    },
    {
      title: "Signatures & Deposits",
      text: (
        <p className="text-xl">
          Your Master Services Agreement, IP agreement and project order are
          automatically created and ready to sign, and your deposit is ready to
          be collected.
        </p>
      ),
    },
    {
      title: "Invoices & Adjustments",
      text: (
        <p className="text-xl">
          Your new project link will be used to share your invoices, track
          adjustments & revisions, and manage the full lifecycle of your
          contract with your customer.
        </p>
      ),
    },
  ];

  return (
    <div className={`${props.className}`}>
      <h1 className="my-5 px-5 capitalize text-3xl font-bold text-SocoOrangeLight">
        {guideDetails[props.index].title}
      </h1>
      <p className="px-5 my-5">{guideDetails[props.index].text}</p>
    </div>
  );
}

export default GuideText;
