import { Form } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Loader from "../../elements/Loader";
import moment from "moment";
import { useState, useEffect } from "react";
import Submit from "../../inputs/Submit";
import Appointment from "../../inputs/Appointment";
import {
  GhostButton,
  SubHeader,
  Div,
  Text,
  Label,
} from "../../customComponents";

export default ({ cloudUtils, project, profile }) => {
  const [templates, setTemplates] = useState({});

  let [invalid, setInvalid] = useState(false);
  let [day, setDay] = useState(moment(project.discoveryStart));
  const [loading, setLoading] = useState(false);
  const [editTime, editTimeBtn] = useState(false);

  useEffect(() => {
    project.services.forEach((tid) => {
      cloudUtils.getDocument("template", tid, (v) =>
        setTemplates((prev) => {
          prev[tid] = v;
          return { ...prev };
        })
      );
    });
  }, [project]);

  let projectStartTime = moment(project.discoveryStart);
  let projectEndTime = moment(project.discoveryStart).add(
    project.discoveryDuration,
    "minutes"
  );

  let submitEstimate = (values) => {
    if (invalid) {
      alert("Please select a valid date!");
      return;
    }
    const t2 = moment(day).add(project.discoveryDuration, "minutes");
    let invoice = new Date().toISOString().slice(5, 16).replace(/[T:-]/g, "");
    invoice = `${project.id.slice(0, 4)}-${invoice}`;

    setLoading(true);
    cloudUtils
      .updateDocument(
        "project",
        project.id,
        {
          busy: [day.valueOf(), t2.valueOf()],
          invoice,
          status: "DISCOVERY",
        },
        "UPDATE"
      )
      .then(() => window.location.reload());
  };

  return (
    <div>
      {loading && <Loader />}

      {Object.entries(templates).map(([id, { survey = {}, name }]) => {
        return (
          <Div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {" "}
            <SubHeader>{name}</SubHeader>
            {Object.entries(survey).map(([key, { question }]) => {
              return (
                <>
                  <Label>{question}</Label>
                  <Text>{project.intake[`${id}.survey.${key}`]}</Text>
                  <br />
                </>
              );
            })}
            <Label>Questions & Comments</Label>
            <Text break>{project.intake[`${id}.notes`]}</Text>
            <br />
          </Div>
        );
      })}

      <br />
      <Div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <SubHeader>Booking Details</SubHeader>
        <Label>Customer Email</Label>
        <Text>{project.email}</Text>
        <br />

        <Form
          requiredMark={false}
          align="left"
          style={{ width: "100%" }}
          initialValues={{
            time: project.discoveryStart
              ? [projectStartTime, projectEndTime]
              : null,
          }}
          onFinish={submitEstimate}
        >
          <Label>Appointment Time</Label>
          {editTime ? (
            <Appointment
              day={day}
              setInvalid={setInvalid}
              setDay={setDay}
              duration={project.discoveryDuration}
              openHours={profile.openHours}
              busy={profile.busy}
              showTime={true}
              closedDays={profile.closedDays}
            />
          ) : (
            <Text>
              {projectStartTime.format("MMM Do, YYYY (h:mm a - ")}
              {projectEndTime.format("h:mm a)")}
              <GhostButton
                icon={<EditOutlined />}
                type="text"
                onClick={() => editTimeBtn(true)}
              />{" "}
            </Text>
          )}
          <Submit text="Confirm Appointment" />
        </Form>
      </Div>
    </div>
  );
};
