import { Form, Space } from "antd";
import { SubmitButton, BigButton } from "../customComponents";

export default ({ text, onClick, big, margin = false, ...props }) => {
  if (onClick) {
    return (
      <Form.Item
        style={{
          "align-items": "initial",
          marginTop: margin || 32,
        }}
      >
        <center>
          {big ? (
            <BigButton onClick={onClick} {...props}>
              {text}
            </BigButton>
          ) : (
            <Space size="large">
              <SubmitButton onClick={onClick} {...props}>
                {text}
              </SubmitButton>
            </Space>
          )}
        </center>
      </Form.Item>
    );
  }

  return (
    <Form.Item style={{ marginTop: margin || 32 }}>
      <center>
        {big ? (
          <BigButton htmlType="submit" {...props}>
            {text}
          </BigButton>
        ) : (
          <Space size="large">
            <SubmitButton
              htmlType="submit"
              shape="round"
              {...props}
            >
              {text}
            </SubmitButton>
          </Space>
        )}
      </center>
    </Form.Item>
  );
};
