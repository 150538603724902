import React from "react";

function HelpBlock({ text }: { text: String }) {
  return (
    <div
      className="bg-SocoWhite"
      style={{
        position: "absolute",
        bottom: 0,
        height: "fit-content",
        maxWidth: "75%",
        right: 0,
        borderRadius: "6px",
        clipPath:
          "polygon(10% 0%, 80% 0%, 100% 0%, 100% 75%, 90% 100%, 0% 100%, 0% 100%, 0% 25%)",
      }}
    >
      <p className="p-3 text-xl font-bold text-SocoBlack">{text}</p>
    </div>
  );
}

export default HelpBlock;
