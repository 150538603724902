import { Col, Row, Switch } from "antd";
import { SubHeader, BorderDiv, RegularButton } from "../customComponents";
import { EditOutlined } from "@ant-design/icons";

export default ({
  title,
  setActive = false,
  active,
  desc,
  action,
  status,
  onClick,
  highlight,
}) => {
  console.log(title);
  return (
    <BorderDiv
      style={{ width: "100%", borderColor: highlight ? "#d11a2a" : null }}
      onClick={onClick}
    >
      <Row>
        <Col span={setActive ? 18 : 24} style={{ textAlign: "left" }}>
          <SubHeader>
            {title}{" "}
            {status && (
              <span style={{ fontWeight: "lighter" }}>| {status}</span>
            )}
          </SubHeader>
        </Col>
        {setActive && (
          <Col span={6} align="right" style={{ paddingRight: 12 }}>
            <Switch
              checked={active}
              onChange={setActive}
              checkedChildren="Included"
              unCheckedChildren="Hidden"
            />
          </Col>
        )}
        <Col span={24} align="left">
          {desc}

          <br />
          {action && (
            <RegularButton
              className="floating-action"
              style={{ borderColor: highlight ? "#d11a2a" : null }}
            >
              {action} <EditOutlined />
            </RegularButton>
          )}
        </Col>
      </Row>
    </BorderDiv>
  );
};
