import { Row, Col } from "antd";
import TemplateCard from "../cards/TemplateCard";
import { useNavigate } from "react-router-dom";
import {
  SubHeader,
  Divider,
  SubmitButton,
  Text,
  AccentDiv,
} from "../customComponents";
import Options from "../forms/LibrarySelect";
import { useState } from "react";

export default ({ cloudUtils, profile, templates, admin }) => {
  let navigate = useNavigate();
  let [selected, setSelected] = useState();
  let [active, setActive] = useState({});

  const servicesButton = {
    width: "100%",
    padding: "16px",
    margin: "16px 0 12px",
  };

  return (
    <Row gutter={[0, 24]} style={{ paddingBottom: 16, maxWidth: 768 }}>
      {Object.values(templates).length ? (
        Object.values(templates).map((temp) => {
          return (
            <TemplateCard
              onClick={() => navigate(`/${profile.id}/details/${temp.id}`)}
              cloudUtils={cloudUtils}
              id={temp.id}
              key={"template" + temp.id}
              admin={admin}
              setActive={(checked) =>
                setActive((prev) => {
                  prev[temp.id] = checked;
                  cloudUtils.updateDocument(
                    "template",
                    temp.id,
                    { show: checked },
                    "UPDATE"
                  );
                  return { ...prev };
                })
              }
              active={temp.id in active ? active[temp.id] : temp.show}
              {...temp}
              profile={profile}
            />
          );
        })
      ) : (
        <Text style={{ marginBottom: 16 }}>
          Create services that define the scope of your project. When your
          clients visit your page, they can select the services they need and
          book you for an initial meeting!
        </Text>
      )}

      {admin && (
        <AccentDiv style={servicesButton}>
          <Row>
            <Col align="left">
              <SubHeader style={{ paddingRight: 0 }}>Add Service </SubHeader>
            </Col>
          </Row>
          <br />
          <Options
            selected={selected}
            setSelected={setSelected}
            cloudUtils={cloudUtils}
          />
          <br />
          <br />

          <SubmitButton
            block
            onClick={() => {
              if (selected) {
                navigate(`/${profile.id}/add-template?service=${selected}`);
              } else {
                alert("Please select a template from our service library");
              }
            }}
          >
            Create from Template
          </SubmitButton>

          <Divider displayText="OR" />
          <SubmitButton
            block
            onClick={() => {
              navigate(`/${profile.id}/add-template`);
            }}
          >
            Start from Scratch
          </SubmitButton>
        </AccentDiv>
      )}
    </Row>
  );
};
