import { Modal, Form } from "antd";
import { useEffect, useState } from "react";
import TemplateForm from "../forms/TemplateForm";
import { useNavigate, useParams } from "react-router-dom";

import {
  CalendarOutlined,
  DeleteOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { GhostButton, DangerGhostButton } from "../customComponents";
import Layout from "../elements/Layout";
const { confirm } = Modal;

export default ({ cloudUtils, user }) => {
  const navigate = useNavigate();
  const { profileId, templateId } = useParams();
  const goBack = () => navigate(-1);
  const [template, setTemplate] = useState({
    price: {
      min: 1,
      max: 2,
      value: 0,
      currency: "CAD",
    },
    time: {
      min: 1,
      max: 2,
      value: 1,
      unit: "Days",
    },
  });
  const [profile, setProfile] = useState({});

  // Object.values(total).reduce((a, b) => a + b, 0) + Object.values(counts).reduce((a, b) => a + b, 0)

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    cloudUtils.getDocument("template", templateId, setTemplate);
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, [profileId, templateId]);

  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
  }, [profile]);

  const deleteItem = async () => {
    await cloudUtils.deleteTemplate(templateId);
    goBack();
  };

  let adminButtons = admin
    ? [
        <GhostButton icon={<SaveOutlined />} onClick={form2.submit} />,
        <DangerGhostButton
          icon={<DeleteOutlined />}
          onClick={showDeleteConfirm}
        />,
      ]
    : [<GhostButton icon={<CalendarOutlined />} onClick={form.submit} />];

  // let previewButton = admin
  //   ? <Button onClick={() => setCustomerPreview(!customerPreview)} shape='round'>Customer Preview:&nbsp;{customerPreview? <span style={{color: 'green'}}>ON</span>:<span style={{color: accentColor}}>OFF</span>}</Button>
  //   : null

  function showDeleteConfirm() {
    confirm({
      title: "Are you sure delete this service?",
      icon: <ExclamationCircleOutlined />,
      content: "Deleting service: " + template.name,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteItem();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <Layout
      title={template.name}
      onBack={goBack}
      extra={adminButtons}
      theme={profile.theme}
    >
      <TemplateForm
        cloudUtils={cloudUtils}
        profile={profile}
        form={form2}
        templateId={templateId}
        old={true}
      />
    </Layout>
  );
};
