import { Form, InputNumber } from "antd";
import { Label } from "../customComponents";

const NumberInput = ({
  displayText,
  label,
  required = true,
  fieldSize,
  validators = [],
  ...rest
}) => {
  return (
    <Form.Item
      label={<Label>{displayText}</Label>}
      name={label}
      rules={[
        {
          required,
          message: `Please input a ${displayText}`,
        },
        ...validators,
      ]}
    >
      <InputNumber
        style={{
          borderRadius: 12,
        }}
        {...rest}
      />
    </Form.Item>
  );
};

export default NumberInput;
