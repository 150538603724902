import { Form, Input, Select, Steps } from "antd";
import { v4 as uuidv4 } from "uuid";
import Survey from "./dynamic/Survey";
import Quote from "./dynamic/Quote";
import Bundle from "./dynamic/Bundle";
import TextInput from "../inputs/TextInput";
import ToggleInput from "../inputs/ToggleInput";
import BigTextInput from "../inputs/BigTextInput";
import { useState, useEffect } from "react";
import {
  Divider,
  SubmitButton,
  Text,
  Label,
  SubHeader,
  Tooltip,
  Div,
} from "../customComponents";
import { bundleLength } from "../utils/validators";
import Submit from "../inputs/Submit";
import toolTips from "../utils/toolTips";
import Options from "../forms/TagSelect";

import { useNavigate, useParams } from "react-router-dom";

export default ({ cloudUtils, profile, templateId, serviceId, form, old = false }) => {
  let { profileId } = useParams();
  const navigate = useNavigate();

  const bundles = Form.useWatch("bundle", form);
  const [serviceText, setServiceName] = useState();
  const [currency, setCurrency] = useState("CAD");
  const [free, setFree] = useState(false);
  const [unit, setUnit] = useState("Days");
  const [template, setTemplate] = useState(false);
  const [selected, setSelected] = useState([]);
  const [current, setCurrent] = useState(0);
  const [type, setType] = useState("digital");

  useEffect(() => {
    if (serviceId) {
      cloudUtils.getDocument("library", serviceId, setTemplate);
    }
    cloudUtils.getDocument("template", templateId, setTemplate);
  }, []);

  useEffect(() => {
    if (template) {
      let newTemplate = { ...template };
      setServiceName(newTemplate.name);
      setSelected(newTemplate.tags || []);
      console.log(template);
      newTemplate["free"] = newTemplate.price.free;
      setCurrency(newTemplate.price.currency);
      setFree(newTemplate.free);
      if (newTemplate.time) {
        newTemplate["timeValue"] = newTemplate.time.value;
        setUnit(newTemplate.time.unit);
      }

      setType(newTemplate.type || "digital");

      newTemplate["survey"] = newTemplate.survey
        ? Object.entries(newTemplate["survey"]).map(([id, vals]) => {
            return { id, ...vals };
          })
        : [];

      newTemplate["quote"] = newTemplate.quote
        ? Object.entries(newTemplate["quote"]).map(([id, vals]) => {
            return { id, ...vals };
          })
        : [];

      newTemplate["bundle"] = newTemplate.bundle
        ? Object.entries(newTemplate["bundle"])
            .map(([id, vals]) => {
              return { id, ...vals };
            })
            .sort((a, b) => {
              if (a.base && !b.base) {
                return -1;
              } else if (!a.base && b.base) {
                return 1;
              } else {
                return 0;
              }
            })
        : [];

      form.setFieldsValue(newTemplate);
    }
  }, [template]);

  let TemplatePage = async (raw) => {
    let { file, timeValue, timeMin, timeMax, ...values } = raw;

    if (values.survey) {
      let surveys = {};
      values.survey.forEach((survey) => {
        let { id, question } = survey;
        surveys[id || uuidv4()] = { question, type: "text" };
      });
      values.survey = { ...surveys };
    }

    if (values.quote) {
      let quotes = {};
      values.quote.forEach((quote) => {
        let { id, feature, price, count, packages } = quote;
        quotes[id || uuidv4()] = { feature, price, count, packages };
      });
      values.quote = { ...quotes };
    }

    if (values.bundle) {
      let bundles = {};
      values.bundle.forEach((bundle) => {
        let { id, title, price, description = "", base = false } = bundle;
        bundles[id || uuidv4()] = { title, price, description, base };
      });
      values.bundle = { ...bundles };
    }

    if (!free) {
      if (!Object.keys(values.bundle).length) {
        alert("No packages have been created!");
        return;
      }
      values.price = {
        currency,
        free: free,
      };
    } else {
      values.price = {
        currency,
        free: free,
      };
    }

    if (["digital", "product"].includes(type)) {
      values.time = {
        unit,
        value: parseInt(timeValue),
      };
    }

    values.profile = profileId;

    if (file) {
      values.serviceImage =
        template +
        "/" +
        new Date().getTime() +
        "." +
        file[0].name.split(".").pop();
      await cloudUtils.uploadFile(values.serviceImage, file);
    }

    let filtered = Object.fromEntries(
      Object.entries(values).filter(([k, v]) => v)
    );

    filtered.purchase = ["digital", "product"].includes(type);
    filtered.tags = selected;
    filtered.type = type;
    if (!old) {
      filtered.show = true;
    }
    await cloudUtils.updateDocument(
      "template",
      templateId,
      filtered,
      old ? "UPDATE" : "MERGE"
    );

    console.log(`/${profileId}/${profile.demo ? "/clauses" : ""}`);
    navigate(`/${profileId}/${profile.demo ? "/clauses" : ""}`);
  };

  const selectCurrency2 = (
    <Select defaultValue="CAD" value={currency} onSelect={setCurrency}>
      <Select.Option value="CAD">CAD</Select.Option>
      <Select.Option value="USD">USD</Select.Option>
    </Select>
  );

  const charLimitValidator = (displayText, textValue, limit) => {
    return {
      validator: () => {
        if (textValue && textValue.length <= limit) {
          return Promise.resolve();
        }
        return Promise.reject();
      },
      message: `${displayText} must be between 1 and ${limit} characters`,
      validateTrigger: "onSubmit",
    };
  };

  const priceType = {
    Free: (
      <center>
        <Text>Free services have payment integrations disabled</Text>
      </center>
    ),
    Package: (
      <>
        <Div>
          <Label>Currency</Label>
          <span style={{ marginLeft: 16 }}>{selectCurrency2}</span>
          <br />
          <br />

          <Label>Costs</Label>
          <Tooltip
            text="
          Include all the costs you incur while working on this service. Costs
          can be binary (included or not included) or by unit (by hour, by word
          or by person).
        "
          />

          <Quote bundles={bundles} form={form} />
        </Div>
        <br />
        <Div>
          <Label>Scenarios</Label>

          <Tooltip
            text="
          Sometimes, costs will change or only be applicable in certain
          scenarios. For example, if you have a premium package with some
          features included, you can add it as a scenario.
        "
          />
          <div style={{ height: 8 }} />
          <Bundle validators={[bundleLength]} />
          <Label style={{ fontWeight: 700 }} fontSize="24px">
            Only your final quote will be shared
          </Label>
          <Tooltip
            text="
          Your costs are never shared with your customers. This section helps
          you calculate your earnings and guide your pricing during discovery.
          Include hourly costs and expense equipment specific to different
          packages.
        "
          />
        </Div>
      </>
    ),
  };

  const priceComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Div>
        <ToggleInput
          displayText="Free Service?"
          label="free"
          state={free}
          setState={setFree}
          style={{ marginBottom: 0 }}
        />
      </Div>
      <br />
      <Form.Item rules={[{ required: true }]}>
        <Input.Group>{priceType[free ? "Free" : "Package"]}</Input.Group>
      </Form.Item>
    </div>
  );

  const finalComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Div>
        <Label>Intake</Label>
        <Tooltip
          title="What information do you need for your customers before they book this
        service? Your customers will be prompted for these questions before
        booking."
        />
        <Survey />
      </Div>
    </div>
  );

  const updateComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Submit
        big
        style={{ position: old ? "sticky" : "relative" }}
        text={old ? "Update" : "Create"}
      />
    </div>
  );

  const detailComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Div>
        <Text>
          Describe your service in detail. This will be shown to your customers
          on your SoCo home page.
        </Text>
        <br />
        <TextInput
          required
          displayText="Service Name"
          label="name"
          validators={[charLimitValidator("Service Name", serviceText, 50)]}
          onChange={(e) => {
            setServiceName(e.target.value);
          }}
        />
        <BigTextInput
          required
          displayText="Description "
          label="longDesc"
          tooltip={toolTips.details}
        />
        <label className="ant-col ant-form-item-label">
          <Label>Search Tags</Label>
        </label>
        <Options selected={selected} setSelected={setSelected} />
        <br />
        <br />
      </Div>
    </div>
  );

  return (
    <Form
      onFinish={TemplatePage}
      onFinishFailed={() =>
        alert("Please verify all fields have been filled correctly")
      }
      form={form}
      layout="vertical"
      requiredMark={false}
      align="left"
      style={{ paddingBottom: 48 }}
    >
      <Steps
        current={current}
        labelPlacement="vertical"
        onChange={setCurrent}
        style={{ margin: "12px 0 0" }}
        size="small"
        responsive={false}
        items={[
          {
            title: "Summary",
          },
          {
            title: "Costs",
          },
          {
            title: "Intake",
          },
        ]}
      />

      {detailComp(current == 0)}
      {priceComp(current == 1)}
      {finalComp(current == 2)}
      {updateComp(current == 2)}
      <br />
      {current < 2 && (
        <SubmitButton onClick={() => setCurrent((cur) => cur + 1)} block>
          Next
        </SubmitButton>
      )}
    </Form>
  );
};
