import { Form, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { BlackLabel, Label } from "../customComponents";

const TextInput = ({
  black = false,
  displayText,
  label,
  initial = null,
  required,
  tooltip,
  validators = [],
  style,
  price,
  tight,
  ...rest
}) => {
  const inputBoxStyle = {
    borderRadius: 12,
  };

  const Lab = ({ children }) =>
    black ? <BlackLabel>{children}</BlackLabel> : <Label>{children}</Label>;

  let newValidators = [...validators];

  return (
    <Form.Item
      className={tight ? "tight" : null}
      labelCol={{ span: 24 }}
      label={
        displayText ? (
          <Lab>
            {displayText}
            {tooltip && (
              <Tooltip title={tooltip}>
                <sup>
                  <InfoCircleOutlined
                    style={{ fontSize: 12, paddingLeft: 12 }}
                  />
                </sup>
              </Tooltip>
            )}
          </Lab>
        ) : null
      }
      name={label}
      initialValue={initial}
      rules={[
        {
          required,
          message: ``,
        },
        ...newValidators,
      ]}
      style={style}
    >
      {price ? (
        <Input
          prefix="$"
          style={inputBoxStyle}
          defaultValue={initial}
          {...rest}
        />
      ) : (
        <Input defaultValue={initial} style={inputBoxStyle} {...rest} />
      )}
    </Form.Item>
  );
};

export default TextInput;
