import { Form, Select } from "antd";

const { Option } = Select;

export default ({ bundles, label, required=true, ...props }) => {
  return (
    <Form.Item label={null} required={required} name={label} style={{ flexGrow: 1, marginBottom: 0 }}>
      <Select mode="multiple" style={{ width: "100%" }} {...props}>
        {bundles.filter(({title}) => title).map(({ id, title }) => (
          <Option value={id} >{title}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};
