import React, { useEffect, useState } from "react";
import {
  Phone1Base,
  Phone1Layer,
  Phone2Base,
  Phone2Layer1,
  Phone2Layer2,
  Phone2Layer3,
  Phone3Base,
  Phone3Layer1,
  Phone4Base,
  Phone4Layer1,
  Phone4Layer2,
} from "../assets/Phones.tsx";
import GuideText from "./GuideText.tsx";
import oneA from "../assets/1a.png";
import oneB from "../assets/1b.png";
import twoA from "../assets/2a.png";
import twoB from "../assets/2b.png";
import threeA from "../assets/3a.png";
import threeB from "../assets/3b.png";
import fourA from "../assets/4a.png";
import fourB from "../assets/4b.png";
import HelpBlock from "./HelpBlock.tsx";

function GuideSteps() {
  const [index, setIndex] = useState(0);
  const [toggled, settoggled] = useState(true);
  const [automatedSwitch, setAutomatedSwitch] = useState(true);

  // useEffect(() => {
  //   const timerId = setInterval(() => {
  //     if (automatedSwitch) {
  //       onAutoStepClicked(index + 1 == phoneDetails.length ? 0 : index + 1);
  //     }
  //   }, 3500);

  //   return () => clearInterval(timerId);
  // }, [automatedSwitch, index]);

  interface PhoneStates {
    base: React.ReactNode;
    layers: React.ReactNode[];
  }

  const onStepClicked = (index: number) => {
    // setAutomatedSwitch(false);
    settoggled(false);
    setTimeout(() => {
      settoggled(true);
      setIndex(index);
    }, 100);
  };

  const onAutoStepClicked = (index: number) => {
    settoggled(false);
    setTimeout(() => {
      settoggled(true);
      setIndex(index);
    }, 300);
  };

  const roundSelected = (item: number) => {
    return index == item ? "bg-SocoOrangeLight" : "bg-transparent";
  };

  const sources = [
    [
      { src: oneA, text: "Showcase your services and links" },
      { src: oneB, text: "Qualify your leads before discovery" },
    ],
    [
      { src: twoA, text: "Track your clients in one place" },
      { src: twoB, text: "Create fair and profitable contracts" },
    ],
    [
      { src: threeA, text: "Get your contracts signed..." },
      { src: threeB, text: "...and your deposits paid" },
    ],
    [
      { src: fourA, text: "Automatically invoice your clients" },
      { src: fourB, text: "Manage project scope and payments" },
    ],
  ];

  const phoneDetails: PhoneStates[] = [
    {
      base: <Phone1Base />,
      layers: [<Phone1Layer />],
    },
    {
      base: <Phone2Base />,
      layers: [<Phone2Layer1 />, <Phone2Layer2 />, <Phone2Layer3 />],
    },
    {
      base: <Phone3Base />,
      layers: [<Phone3Layer1 />],
    },
    {
      base: <Phone4Base />,
      layers: [<Phone4Layer1 />, <Phone4Layer2 />],
    },
  ];

  const numberAnimation = toggled
    ? "translate-x-0 opacity-100"
    : "translate-x-20 opacity-0";

  const textAnimation = toggled ? "opacity-100" : "opacity-0";

  const phoneAnimation = toggled
    ? "translate-y-0 md:translate-y-0 md:translate-x-0 opacity-100"
    : "-translate-y-20 md:translate-y-0 md:-translate-x-20 opacity-0";

  return (
    <>
      <div className="h-max flex flex-row justify-center items-center px-5 py-10">
        <div
          className={`w-10 h-10 mx-5 md:mx-12 lg:mx-16 transition-all rounded-full border-2 border-SocoOrangeLight cursor-pointer bg ${roundSelected(
            0
          )}`}
          onClick={() => onStepClicked(0)}
        ></div>
        <div
          className={`w-10 h-10 mx-5 md:mx-12 lg:mx-16 transition-all rounded-full border-2 border-SocoOrangeLight cursor-pointer bg ${roundSelected(
            1
          )}`}
          onClick={() => onStepClicked(1)}
        ></div>
        <div
          className={`w-10 h-10 mx-5  md:mx-12 lg:mx-16  transition-all rounded-full border-2 border-SocoOrangeLight cursor-pointer bg ${roundSelected(
            2
          )}`}
          onClick={() => onStepClicked(2)}
        ></div>
        <div
          className={`w-10 h-10 mx-5  md:mx-12 lg:mx-16  transition-all rounded-full border-2 border-SocoOrangeLight cursor-pointer bg ${roundSelected(
            3
          )}`}
          onClick={() => onStepClicked(3)}
        ></div>
      </div>

      <div className="min-h-[1400px] md:min-h-[700px] w-full">
        <div className="w-4/5 xl:w-2/3 relative border-2 border-SocoOrangeLight rounded-xl ">
          <div
            className={`transition-all absolute text-SocoOrangeLight top-0 -left-12 rounded bg-SocoBlack py-5 mt-5 mx-2 ${numberAnimation}`}
          >
            <p className="text-7xl ml-2 md:text-9xl md:ml-[-1rem] text-SocoOrangeLight">{index + 1}</p>
          </div>


          <GuideText
            index={index}
            className={`flex flex-col items-center transition-all ${textAnimation} m-5 md:flex-1`}
          />

          <div className="flex flex-col justify-evenly md:flex-row pb-10" style={{rowGap: 32}}>
            <div
              style={{
                maxWidth: "calc(100% - 8px)",
                width: "300px",
                position: "relative",
              }}
            >
              <img width="300px" style={{paddingBottom: "5%"}} src={sources[index][0].src} />
              <HelpBlock
                text={sources[index][0].text}
              />
            </div>
            <div
              style={{
                maxWidth: "calc(100% - 8px)",
                width: "300px",
                position: "relative",
              }}
            >
              <img width="300px" style={{paddingBottom: "5%"}} src={sources[index][1].src} />
              <HelpBlock
                text={sources[index][1].text}
              />
            </div>
          </div>

          {/* <div
            className={`m-5 transition-all ${phoneAnimation} w-1/2 md:w-1/4 md:relative`}
          >
            {phoneDetails[index].base}
            {phoneDetails[index].layers}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default GuideSteps;
