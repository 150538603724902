import React from "react";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { DangerGhostButton, RegularButton } from "../../customComponents";
import BigTextInput from "../../inputs/BigTextInput";

function Survey() {
  return (
    <div style={{ marginTop: 16 }}>
      <Form.List name="survey">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <div
                  key={field.key}
                  style={{
                    marginBottom: 16,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BigTextInput
                    lines={2}
                    tight
                    required={true}
                    placeholder="Question"
                    style={{ flexGrow: 1, paddingRight: 16, marginBottom: 0 }}
                    label={[index, "question"]}
                  />
                  <DangerGhostButton
                    onClick={() => remove(field.name)}
                    icon={<CloseCircleOutlined />}
                  />
                </div>
              ))}
              <Form.Item>
                <center>
                  <RegularButton onClick={add} icon={<PlusOutlined />}>
                    Add Question
                  </RegularButton>
                </center>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
}

export default Survey;
