import { Form, Switch } from "antd";
import { Label } from "../customComponents";
const ToggleInput = ({
  displayText,
  label,
  state,
  dummy = false,
  formless = false,
  setState,
  style = {},
  checked = "Yes",
  unChecked = "No",
}) => {
  if (formless) {
    return (
      <>
        <Label>{displayText}</Label>
        <br/>
        <Switch
          checked={state}
          onChange={() => setState(!state)}
          checkedChildren={checked}
          unCheckedChildren={unChecked}
          style={{ marginTop: 8, marginBottom: 16, ...style }}
        />
      </>
    );
  }
  if (dummy) {
    return (
      <Form.Item
        name={label}
        label={displayText && <Label>{displayText}</Label>}
        style={{ "flex-wrap": "wrap",  alignItems: 'end', ...style }}
        valuePropName="checked"
      >
        <Switch
          checkedChildren={checked}
          unCheckedChildren={unChecked}
          disabled={true}
        />
      </Form.Item>
    );
  }
  return (
    <Form.Item
      name={label}
      label={displayText && <Label>{displayText}</Label>}
      style={{ "flex-wrap": "wrap",  alignItems: 'end', ...style }}
      valuePropName="checked"
    >
      <Switch
        checked={state}
        onChange={() => setState(!state)}
        checkedChildren={checked}
        unCheckedChildren={unChecked}
      />
    </Form.Item>
  );
};

export default ToggleInput;
