import { Form, Upload, Select, Modal, message } from "antd";
import {
  UploadOutlined,
  DollarCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import logo from "../icons/icon.png";
import TextInput from "../inputs/TextInput";
import BigTextInput from "../inputs/BigTextInput";
import toTitleCase from "../utils/titleCase";
import Submit from "../inputs/Submit";
import toolTipText from "../utils/toolTips";
import Links from "./dynamic/Links";
import LocationInput from "../inputs/LocationInput";
import ToggleInput from "../inputs/ToggleInput";
import Info from "../elements/Info";
import TimeRangeInput from "../inputs/TimeRangeInput";
import moment from "moment";
import BusyDays from "../inputs/BusyDays";
import ImgCrop from "antd-img-crop";
import {
  RegularButton,
  Div,
  DangerGhostButton,
  Label,
  DangerButton,
  Tooltip,
  Text,
  OptionTag,
} from "../customComponents";
import NumberInput from "../inputs/NumberInput";
import { charLimitFormValidator, phoneValidator } from "../utils/validators";

const { confirm } = Modal;

const prefixes = {
  instagram: "instagram.com/",
  youtube: "youtube.com/",
  spotify: "open.spotify.com/artist/",
  github: "github.com/",
  facebook: "facebook.com/",
  linkedin: "linkedin.com/in/",
  tiktok: "tiktok.com/@",
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    alert("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    alert("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default ({
  auth,
  cloudUtils,
  setLoading,
  profile,
  profileId,
  setSave,
  startSpinner,
}) => {
  const [showSocials, setShowSocials] = useState([]);
  const [activeSocials, setActiveSocials] = useState(Object.keys(prefixes));
  const [existingLinks, setExistingLinks] = useState({});
  const [cardPayment, setCardPayment] = useState(true);
  const [section, setSection] = useState("Profile");
  const [shareEmail, setShareEmail] = useState(false);
  const [directPayment, setDirectPayment] = useState(false);
  const [theme, setTheme] = useState(false);
  const [hex, setHex] = useState("#000000");
  const [imgLoad, setImgLoad] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setImgLoad(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setImgLoad(false);
        setImageUrl(url);
      });
    }
  };

  let [fileList, setFileList] = useState(false);

  const [form] = Form.useForm();
  const [debounce, setDebounce] = useState();

  const [taxLocation, setTaxLocation] = useState();
  const [address, setAddress] = useState();

  const fileDelete = async (info) => {
    let filePath = profileId + "/" + info.name;
    await cloudUtils.deleteFile(filePath).then(() => {
      message.success(`${info.name} file deleted successfully`);
    });
  };

  const fileUpload = async ({ onError, onSuccess, file }) => {
    if (file) {
      let filePath = profileId + "/promo/" + file.name;
      try {
        const data = await cloudUtils.uploadFileWithoutOriginFileObj(
          filePath,
          file
        );
        onSuccess(null, data);
      } catch (e) {
        onError(e);
      }
    }
  };

  const handleUploadChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(fileList);

    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileList(info.fileList);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status == "removed") {
      fileDelete(info.file);
    }
  };

  useEffect(() => {
    try {
      cloudUtils.getUploadedFiles(`${profileId}/promo/`, setFileList);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    let fields = { location: address || "" };
    if (Object.keys(taxLocation || {}).length) {
      fields.taxLocation = taxLocation;
    }
    cloudUtils
      .updateDocument("profile", profileId, fields, "UPDATE")
      .catch(console.log);
  }, [address, taxLocation]);

  let onboard = cloudUtils.getFunction("onboard");
  const toOnboard = () => {
    setLoading(true);
    onboard({
      email: profile.email,
      id: profileId,
    }).then(({ data }) => {
      window.location.href = data.url;
    });
  };

  let dashboard = cloudUtils.getFunction("dashboard");
  const toDashboard = () => {
    setLoading(true);
    dashboard({
      stripeId: profile.stripeId,
    }).then(({ data }) => {
      window.location.href = data.url;
    });
  };

  const onFinish = (base, raw) => {
    startSpinner(true);
    if (debounce) {
      clearTimeout(debounce);
    }
    form
      .validateFields()
      .then(() => {
        setSave(true);
      })
      .catch((e) => {
        if (e.errorFields.length) {
          setSave(false);
          startSpinner(false);
          // form.scrollToField(e.errorFields[0].name)
        } else {
          setSave(true);
        }
      });

    setDebounce(
      setTimeout(async () => {
        let { phone, closedDays, openHours, uploaded, pic, links, ...values } =
          raw || base;

        let filtered = Object.fromEntries(
          Object.entries(values).map(([k, v]) => {
            let site = k.replace("socials.", "");
            if (Object.keys(prefixes).includes(site) && v) {
              if (v.includes(prefixes[site])) {
                return [k, v.substring(v.indexOf(prefixes[site]))];
              }
              return [k, prefixes[site] + v];
            } else {
              return [k, v];
            }
          })
        );
        activeSocials.forEach((social) => {
          filtered[`socials.${social}`] = "";
        });

        if (!phone || (phone && String(phone).length != 10)) {
          filtered.phone = false;
        } else {
          filtered.phone = phone;
        }

        if (uploaded && uploaded[0].name) {
          filtered.uploaded =
            profileId +
            "/" +
            new Date().getTime() +
            "." +
            uploaded[0].name.split(".").pop();
          filtered.theme = "uploaded";
          await cloudUtils.uploadFile(filtered.uploaded, uploaded);
        }

        filtered.location = address;

        if (openHours) {
          filtered.openHours = openHours.map((d) => d.format("HH:mm"));
        }

        if (closedDays) {
          filtered.closedDays = closedDays;
        }

        filtered.location = filtered.location || "";
        filtered.cardPayment = cardPayment;

        filtered.theme = theme == "flat" ? hex : theme;
        filtered.links = (links || [])
          .map(({ text, url }) =>
            text && url
              ? { text, url: url.replace(/(https)?(http)?:?(\/\/)?/, "") }
              : false
          )
          .filter((t) => t);
        await cloudUtils.updateDocument(
          "profile",
          profileId,
          filtered,
          "UPDATE"
        );
        startSpinner(false);
      }, 250)
    );
  };

  function deleteAccount() {
    confirm({
      title: "Delete Profile?",
      content: `
            Are you sure you want to delete your profile? You cannot undo this action, and will lose all of your data! 
          `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cloudUtils.deleteProfile(profileId, auth().currentUser).then(() => {
          window.location = "//socoapp.ca";
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  useEffect(() => {
    cloudUtils
      .updateDocument(
        "profile",
        profileId,
        {
          theme: theme == "flat" ? hex : theme,
          shareEmail,
          directPayment,
          cardPayment,
        },
        "MERGE"
      )
      .catch(console.log);
  }, [cardPayment, theme, hex, shareEmail, directPayment]);

  useEffect(() => {
    let newProfile = { ...profile };
    let sites = Object.keys(profile.socials || {});

    const removeSocials = [];
    sites.forEach((social) => {
      if (profile.socials[social] && social !== "website") {
        removeSocials.push(social);
      }
      if (social in prefixes) {
        Object.assign(newProfile, profile, {
          ["socials." + social]: profile.socials[social].replace(
            prefixes[social],
            ""
          ),
        });
      } else {
        Object.assign(newProfile, profile, {
          ["socials." + social]: profile.socials[social],
        });
      }
    });

    setShowSocials(removeSocials);
    setActiveSocials(activeSocials.filter((x) => !removeSocials.includes(x)));

    setExistingLinks(profile.links || []);
    if (profile.pic) {
      cloudUtils
        .getDownloadURL(profile.pic)
        .then((src) => setImageUrl(src))
        .catch(() => setImageUrl(logo));
    }
    setCardPayment(newProfile.cardPayment || true);
    setShareEmail(newProfile.shareEmail || false);
    if (newProfile.theme && newProfile.theme.startsWith("#")) {
      setTheme("flat");
      setHex(newProfile.theme);
    } else {
      setTheme(newProfile.theme);
    }
    if (newProfile.openHours) {
      newProfile.openHours = newProfile.openHours.map((d) =>
        moment(d, "HH:mm")
      );
    }

    form.setFieldsValue(newProfile);
    setAddress(newProfile.location);
    setLoading(false);
  }, [profile]);

  const picUpload = async (value) => {
    startSpinner(true);

    console.log("pic uploading");
    let picUrl =
      profileId +
      "/profilePic/" +
      new Date().getTime() +
      "." +
      value.name.split(".").pop();
    await cloudUtils.uploadFileWithoutOriginFileObj(picUrl, value);
    await cloudUtils.updateDocument(
      "profile",
      profileId,
      { pic: picUrl },
      "UPDATE"
    );

    startSpinner(false);
  };

  const addSocial = (value) => {
    if (!showSocials.includes(value)) {
      setActiveSocials((prev) => prev.filter((x) => x != value));
      showSocials.push(value);
    }
  };

  const removeLink = (key) => {
    delete existingLinks[key];
    setExistingLinks({ ...existingLinks });
  };

  const profileSec = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <br />
      <center>
        <ImgCrop onModalOk={(e) => picUpload(e)} rotate>
          <Upload
            customRequest={({ onSuccess }) => onSuccess("ok")}
            className="avatar-uploader"
            accept="image/*,video/*,audio/*"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{ width: "100%", borderRadius: "100%" }}
              />
            ) : (
              <div>
                {imgLoad ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Add a Display Picture</div>
              </div>
            )}
          </Upload>
        </ImgCrop>
      </center>
      <br />
      <Div>
        <TextInput displayText="Legal Entity" label="name" required />
        <TextInput
          displayText="Trade Name"
          label="displayName"
          required
          validators={[charLimitFormValidator("Trade Name", 30)]}
        />
        <TextInput
          displayText="Profession"
          label="job"
          placeholder="Enter your job title"
          validators={[charLimitFormValidator("Profession", 30)]}
          required
        />
        <NumberInput
          addonBefore="+1"
          displayText="Phone Number"
          label="phone"
          required={false}
          formatter={(value) => {
            let s = String(value);
            if (s.length > 6) {
              return `(${s.slice(0, 3)}) ${s.slice(3, 6)}-${s.slice(6, 10)}`;
            }
            if (s.length > 3) {
              return `(${s.slice(0, 3)}) ${s.slice(3, 6)}`;
            }
            if (s.length > 0) {
              return `(${s.slice(0, 3)}`;
            }
          }}
          validators={[phoneValidator]}
          parser={(value) => parseInt(value.replace(/\D/g, ""))}
        />
        <ToggleInput
          displayText="Share Email"
          label="shareEmail"
          state={shareEmail}
          setState={setShareEmail}
        />
        <LocationInput
          setTaxLocation={setTaxLocation}
          address={address}
          setAddress={setAddress}
        />
        <label className="ant-col ant-form-item-label">
          <Label>Theme</Label>
        </label>
        <br />
        <Select onSelect={setTheme} style={{ width: 120 }} value={theme}>
          {/* {["soco", "cool", "coffee", "dark", "flat"].map((theme) => ( */}
          {["default", "dark"].map((theme) => (
            <Select.Option value={theme}>{toTitleCase(theme)}</Select.Option>
          ))}
        </Select>
        {/* <br />
      <br />
      {/* {theme == "flat" && (
        <Circle
          colors={[
            "#117864",
            "#0e6251",
            "#16a085",
            "#0e6655",
            "#0b5345",
            "#1d8348",
            "#186a3b",
            "#196f3d",
            "#145a32",
            "#3498db",
            "#21618c",
            "#1b4f72",
            "#2980b9",
            "#1a5276",
            "#154360",
            "#633974",
            "#512e5f",
            "#5b2c6f",
            "#4a235a",
            "#34495e",
            "#212f3c",
            "#1b2631",
            "#2c3e50",
            "#1c2833",
            "#17202a",
            "#9a7d0a",
            "#7d6608",
            "#9c640c",
            "#7e5109",
            "#935116",
            "#784212",
            "#873600",
            "#6e2c00",
            "#943126",
            "#78281f",
            "#7b241c",
            "#641e16",
            "#555555",
            "#1b1e1f",
            "#111111",
          ]}
          color={hex}
          onChange={(color) => {
            setHex(color.hex);
          }}
        />
      )} */}
      </Div>
    </div>
  );

  const promoMaterial = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        Include images and videos to be displayed on your SoCo homepage!
        Visitors to your page can swipe through this media and get a better
        sense of your past work.
      </Text>
      <br />
      <center style={{ margin: "0 32px 16px" }}>
        <Upload
          accept="image/*,video/*,audio/*"
          name="file"
          listType="picture"
          customRequest={fileUpload}
          onChange={handleUploadChange}
          multiple="true"
          fileList={fileList ? fileList : null}
        >
          <RegularButton icon={<UploadOutlined />}>Upload Media</RegularButton>
        </Upload>
      </center>
      {/* <BigTextInput displayText="Bio" label="bio" lines={3}/> */}
    </div>
  );

  const socialsSec = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Div>
        <Text>
          Add links for your users to your SoCo home page! For youtube links,
          videos will be directly displayed on the page. Other links will open
          in a new page.
        </Text>
        <br />
        {showSocials.map((x) => (
          <>
            <DangerGhostButton
              icon={<CloseCircleOutlined size="small" />}
              onClick={() => {
                setShowSocials((prev) => prev.filter((y) => y !== x));
                setActiveSocials((prev) => [x, ...prev]);
                form.submit();
              }}
              style={{ float: "right" }}
            />
            <TextInput
              displayText={toTitleCase(x)}
              label={"socials." + x}
              required={true}
              prefix={prefixes[x]}
            />
          </>
        ))}
        <label className="ant-col ant-form-item-label">
          <Label>Add Socials</Label>
          <Tooltip text={toolTipText.links} />
        </label>
        <br />
        <Select onSelect={addSocial} style={{ width: 120 }}>
          {activeSocials.map((social) => (
            <Select.Option value={social}>{toTitleCase(social)}</Select.Option>
          ))}
        </Select>
      </Div>
    </div>
  );

  const linksSec = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Div>
        <Text>
          Add links for your users to your SoCo home page! For youtube links,
          videos will be directly displayed on the page. Other links will open
          in a new page.
        </Text>
        <br />
        <Links existingLinks={existingLinks} removeLink={removeLink} />
      </Div>
    </div>
  );

  const bookingSec = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Div>
        <TextInput
          displayText="Zoom Personal Meeting ID (PMI)"
          label="zoomPMI"
        />
        <TextInput
          displayText="Zoom Personal Meeting Passcode"
          label="zoomPass"
        />
        <TimeRangeInput
          displayText="Available Hours"
          label="openHours"
          required={false}
        />
        <BusyDays displayText="Busy Days" />
      </Div>
    </div>
  );

  const paymentSec = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Div>
        {/* <ToggleInput
        displayText="Accept Card Payments"
        label="cardPayment"
        state={cardPayment}
        setState={setCardPayment}
      /> */}
        Your paid services will be hidden until payment details are provided
        {cardPayment ? (
          <div align="center" style={{ marginBottom: 24 }}>
            <Submit
              text={
                profile.onboarding ? "Connect Stripe" : "Payments Dashboard"
              }
              onClick={profile.onboarding ? toOnboard : toDashboard}
            />
            <Info
              messages={[
                "With Stripe, accept payments from your customers' Google Pay, Apple Pay, and card transactions.",
                "There is a 2% transaction fee deducted from your payout, this will not be reflected in the customer's purchase price.",
              ]}
            />
            {profile.onboarding ? null : (
              <div align="left">
                <ToggleInput
                  displayText="Accept Direct Payments"
                  label="directPayment"
                  state={directPayment}
                  setState={setDirectPayment}
                />
                <Text>
                  Accept payments directly from your profile without creating a
                  project
                </Text>
              </div>
            )}

            {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label> Remaining Credit:</label>
            <label style={{ fontWeight: "600", paddingBottom: 8 }}>{`$${
              profile.credit ? profile.credit.toFixed(2) : "0"
            }`}</label>
            <Info
              align="center"
              italic
              messages={[
                "Credit will automatically be used to cover transaction fees.",
              ]}
            />
          </div> */}
          </div>
        ) : (
          <>
            <br />
            <br />
            <BigTextInput
              displayText="Payment Instructions"
              label="paymentInstructions"
              initialValue="Contact me on chat to discuss payment details."
            />
            <Info
              messages={[
                "You can include your PayPal or other payment instructions for your customer here.",
                "SoCo does not provide support for these payments, so please use at your own discretion.",
              ]}
            />
          </>
        )}
      </Div>
    </div>
  );

  const accountSec = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Div>
        <Text>
          Once you've deleted your account, you will not be able to reinstate
          it, and all project will be deleted.
        </Text>
        <br />
        <center>
          <DangerButton onClick={deleteAccount}>Delete Account</DangerButton>
        </center>
        {/* tooltip={toolTipText.bank} */}
      </Div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      align="left"
      requiredMark={false}
      form={form}
      name="setting"
      autoComplete="off"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onValuesChange={onFinish}
    >
      <center>
        {["Profile", "Socials", "Links", "Booking", "Payments", "Account"].map(
          (sec) => {
            return (
              <OptionTag
                big={true}
                selected={sec == section}
                onClick={() => {
                  setSection(sec);
                }}
              >
                {sec}
              </OptionTag>
            );
          }
        )}
      </center>
      <br />
      {profileSec(section == "Profile")}
      {socialsSec(section == "Socials")}
      {linksSec(section == "Links")}
      {bookingSec(section == "Booking")}
      {paymentSec(section == "Payments")}
      {accountSec(section == "Account")}
    </Form>
  );
};
