import React, { useState } from "react";
import { Row, Col } from "antd";
import {
  DownCircleOutlined,
  UpCircleOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { BorderDiv } from "../customComponents";

export default ({ title, link }) => {
  let [showLinkContent, setShowLinkContent] = useState(false);

  //Link type check and parse
  function LinkParse() {
    if (link.match(/youtube.+watch/)) {
      let id = link.slice(link.lastIndexOf("=") + 1);
      return <YoutubeContent id={id} />;
    } else if (link.match(/youtu.be/)) {
      let id = link.slice(link.lastIndexOf("/") + 1);
      return <YoutubeContent id={id} />;
    }

    if (link.match(/spotify.+track/)) {
      let id = link.slice(link.lastIndexOf("/") + 1, link.lastIndexOf("?"));
      return <SpotifyContent id={id} />;
    } else if (link.match(/music.apple.+album/)) {
      let id = link.slice(link.indexOf("/", link.lastIndexOf(/album/) + 1) + 1);
      return <AppleMusicContent id={id} />;
    }

    return <NormalContent />;
  }
  //Youtube IFrame
  function YoutubeContent(props) {
    return (
      <>
        <EmbededContent />

        {showLinkContent ? (
          <div style={{ paddingTop: 12 }}>
            <iframe
              width="100%"
              style={{ "aspect-ratio": "16/9" }}
              src={`https://www.youtube.com/embed/${props.id}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        ) : null}
      </>
    );
  }
  //Spotify IFrame
  function SpotifyContent(props) {
    return (
      <>
        <EmbededContent />

        {showLinkContent ? (
          <div style={{ paddingTop: 12 }}>
            <iframe
              src={`https://open.spotify.com/embed/track/${props.id}?utm_source=generator`}
              width="100%"
              height="152"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        ) : null}
      </>
    );
  }
  //Apple Music IFrame
  function AppleMusicContent(props) {
    return (
      <>
        <EmbededContent />
        {showLinkContent ? (
          <div style={{ paddingTop: 12 }}>
            <iframe
              allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
              frameborder="0"
              height="175"
              style={{
                width: "100%",
                overflow: "hidden",
                background: "transparent",
              }}
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
              src={`https://embed.music.apple.com/us/album/${props.id}`}
            ></iframe>
          </div>
        ) : null}
      </>
    );
  }
  //Normal Tab link
  function NormalContent() {
    return (
      <Row
        onClick={() => {
          window.open(`//${link}`, "_blank");
        }}
      >
        <Col offset={4} span={16}>
          <span>{title}</span>
        </Col>
        <Col span={4} style={{ cursor: "pointer" }}>
          <GlobalOutlined />
        </Col>
      </Row>
    );
  }

  //Enhanced Content
  function EmbededContent() {
    return (
      <Row>
        <Col offset={4} span={16}>
          <span>{title}</span>
        </Col>
        <Col span={4}>
          {showLinkContent ? <UpCircleOutlined /> : <DownCircleOutlined />}
        </Col>
      </Row>
    );
  }

  return (
    <BorderDiv
      style={{
        marginBottom: 16,
        borderRadius: 12,
        "font-size": 16,
        padding: 12,
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => {
        setShowLinkContent(!showLinkContent);
      }}
    >
      <LinkParse />
    </BorderDiv>
  );
};
