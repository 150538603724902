import { notification } from "antd";
import { useEffect, useState } from "react";
import ContactCardPage from "../elements/ContactCard";
import Onboarding from "../pages/OnboardingPage";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import Layout from "../elements/Layout";
import logo from "../icons/icon.png";
import { Helmet } from "react-helmet";

export default ({ auth, cloudUtils, user }) => {
  const [retries, setRetries] = useState(0);
  const { profileId } = useParams();
  let [showQr, setQr] = useState(false);
  const navigate = useNavigate();

  const [profile, setProfile] = useState({
    name: "Loading...",
    displayName: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: [],
    theme: "dark",
    authId: ":(",
  });

  let [loading, setLoading] = useState(true);
  let [admin, setAdmin] = useState();

  const [pic, setPic] = useState();

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const profileSetter = (arg) => {
      if (arg) {
        setProfile(arg);
        setLoading(false);
      } else {
        setTimeout(() => {
          setRetries(retries + 1);
        }, 1000);
      }
    };

    if (retries < 5) {
      cloudUtils.getDocument("profile", profileId, profileSetter);
      cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
    } else {
      notification.error({
        message: "Profile not found!",
        description: "Click here to go to home page.",
        onClick: () => {
          auth()
            .signOut()
            .then(() => {
              window.location.href = "//socoapp.ca";
            })
            .catch((error) => {
              console.log(error);
              window.location.href = "//socoapp.ca";
            });
        },
        duration: 0,
      });
    }
  }, [retries, profileId]);

  let onboardingComplete = cloudUtils.getFunction("onboardingComplete");

  let onboard = cloudUtils.getFunction("onboard");
  const toOnboard = () => {
    setLoading(true);
    onboard({
      email: profile.email,
      id: profileId,
    }).then(({ data }) => {
      window.location.href = data.url;
    });
  };

  useEffect(() => {
    let adminTemp = user && user.uid === profile.authId;
    setAdmin(adminTemp);

    if (profile.onboarding && adminTemp) {
      onboardingComplete({
        profile: profileId,
        stripeId: profile.stripeId,
      }).then(({ data }) => {
        console.log(data);
        if (!data.complete) {
          let notifTime = localStorage.getItem("soco-billing-notif");
          // Alert once a day per browser
          if (!notifTime || Date.now() - 86400000 > notifTime) {
            localStorage.setItem("soco-billing-notif", Date.now());
            notification.open({
              message: "Add Billing!",
              description: "Setup Stripe to start accepting card payments and Google Pay for your project contracts.",
              onClick: toOnboard,
            });
          }
        }
      });
    }

    if (profile.pic) {
      cloudUtils
        .getDownloadURL(profile.pic)
        .then((src) => setPic(src))
        .catch(() => setPic(logo))
        .then(() => setLoading(false));
    }
  }, [profile]);

  let stretchy = document.getElementsByClassName("stretchy")[0];
  if (stretchy && stretchy.getAttribute("listener") !== "true") {
    document
      .getElementsByClassName("stretchy")[0]
      .addEventListener("click", () => navigate("//socoapp.ca"), false);
  }

  if (profile.transactional) {
    if (profile.handle) {
      return <Navigate push to={`/${profile.handle}`} />;
    } else {
      return (
        <Onboarding cloudUtils={cloudUtils} profile={profile} auth={auth} />
      );
    }
  } else {
    return (
      <Layout
        page="Menu"
        pageType="User"
        admin={admin}
        loading={loading}
        theme={profile.theme}
      >
        <Helmet>
          <title>{profile.displayName || ""} SoCo</title>
          <meta
            name="description"
            content={`Book with ${profile.displayName} today`}
          />
        </Helmet>
        <ContactCardPage
          setQr={setQr}
          showQr={showQr}
          cloudUtils={cloudUtils}
          profile={profile}
          pic={pic}
          admin={admin}
          showButton={!profile.onboarding && templates.length}
        />
      </Layout>
    );
  }
};
