import "./App.css";

import Hero from "./Sections/Hero.tsx";
import StatusBar from "./Components/StatusBar.tsx";
import Why from "./Sections/Why.tsx";
import What from "./Sections/What.tsx";
import How from "./Sections/How.tsx";
import Reason from "./Sections/Reason.tsx";
import Contact from "./Sections/Contact.tsx";
import Footer from "./Sections/Footer.tsx";
import Hotjar from "@hotjar/browser";
import TagManager from "react-gtm-module";

const siteId = 3517240;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);


TagManager.initialize({
  gtmId: "G-6HH18F9RZS",
});

function LandingPage({ cloudUtils }) {
  return (
    <div className="bg-SocoBlack text-SocoWhite" id="websiteRoot">
      <div className="flex flex-col items-center relative z-0 ">
        <Hero />
        <div className="bg-SocoWhite text-SocoBlack w-full">
          <Reason />
          <Why />
        </div>
        <What />
        <How />
        <Contact cloudUtils={cloudUtils} />
        <Footer />
        <StatusBar />
      </div>
    </div>
  );
}

export default LandingPage;
