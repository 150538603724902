import { UploadOutlined } from "@ant-design/icons";
import { Upload, message, Modal } from "antd";

import { RegularButton } from "../customComponents";
import { useState, useEffect } from "react";

const MeetCard = ({ projectId, cloudUtils, showFiles, setShowFiles }) => {
  let [basePath, setBasePath] = useState(false);
  let [fileList, setFileList] = useState(false);

  useEffect(() => {
    setBasePath(`${projectId}`);
    cloudUtils.getUploadedFiles(`${projectId}`, setFileList);
  }, []);

  const fileUpload = async ({ onError, onSuccess, file }) => {
    if (file) {
      let filePath = basePath + "/" + file.name;
      try {
        const data = await cloudUtils.uploadFileWithoutOriginFileObj(
          filePath,
          file
        );
        onSuccess(null, data);
      } catch (e) {
        onError(e);
      }
    }
  };

  const fileDelete = async (info) => {
    let filePath = basePath + info.name;
    await cloudUtils.deleteFile(filePath).then(() => {
      message.success(`${info.name} file deleted successfully`);
    });
  };

  const handleUploadChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(fileList);

    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileList(info.fileList);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status == "removed") {
      fileDelete(info.file);
    }
  };

  function ShowFiles() {
    if (fileList) {
      return <></>;
    } else {
      return (
        <>
          <div>Loading Uploaded Files...</div>
        </>
      );
    }
  }

  return (
    <div style={{ width: "30%", minWidth: "9rem"  }}>
      <RegularButton
        block={true}
        onClick={() => setShowFiles(true)}
        style={{ minWidth: "9rem" }}
      >
        Share Files
      </RegularButton>

      <div align="center">
        <Modal
          title="File View"
          centered
          open={showFiles}
          onCancel={() => setShowFiles(false)}
          footer={null}
        >
          <center style={{ marginTop: 32 }}>
            <Upload
              accept="image/*,video/*,audio/*"
              name="file"
              customRequest={fileUpload}
              onChange={handleUploadChange}
              multiple="true"
              fileList={fileList ? fileList : null}
            >
              <RegularButton icon={<UploadOutlined />}>
                Upload Files
              </RegularButton>
            </Upload>
            <ShowFiles />
          </center>
        </Modal>
      </div>
    </div>
  );
};

export default MeetCard;
