import { Form, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RegularButton } from "../customComponents";
import { v4 as uuidv4 } from "uuid";
import BigTextInput from "../inputs/BigTextInput";
import { useState, useEffect } from "react";
import qs from "query-string";
import Layout from "../elements/Layout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Submit from "../inputs/Submit";

export default ({ cloudUtils }) => {
  let location = useLocation();

  let { profileId } = useParams();
  const [template, setTemplate] = useState({ name: "Loading..." });
  const [currency] = useState("CAD");
  const [unit] = useState("Days");

  let { templateId } = qs.parse(location.search);
  useEffect(() => {
    cloudUtils.getDocument("template", templateId, setTemplate);
  }, [profileId]);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  let ContractForm = async (raw) => {
    let { file, ...values } = raw;

    let contractId = uuidv4();

    values.template = templateId;
    values.status = "UNVERIFIED";
    values.currency = currency;
    values.unit = unit;

    if (file) {
      let fileName =
        templateId +
        "/" +
        contractId +
        "/final/" +
        "/" +
        new Date().getTime() +
        "." +
        file[0].name.split(".").pop();
      await cloudUtils.uploadFile(fileName, file);
    }

    await cloudUtils.updateDocument("contract", contractId, values);
    navigate({
      pathname: "/" + profileId + "/details/" + templateId,
    });
  };

  return (
    <Layout title={`Add Project to ${template.name}`} onBack={goBack}>
      <div align="center">
        <Form  requiredMark={false} onFinish={ContractForm} layout="vertical">
          <BigTextInput
            displayText="Provide a short description:"
            label="desc"
          />

          <Form.Item
            name="file"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
          >
            <Upload
              customRequest={({ onSuccess }) => onSuccess("ok")}
              className="upload-list-inline"
              accept="image/*,video/*,audio/*"
            >
              <RegularButton
                type="dashed"
                shape="round"
                icon={<UploadOutlined />}
              >
                Upload
              </RegularButton>
            </Upload>
          </Form.Item>

          <Submit text="Add Project" />
        </Form>
      </div>
    </Layout>
  );
};
