import React, { useState, useEffect, useRef } from "react";
import TextInput from "../inputs/TextInput";
import { useParams } from "react-router-dom";
import logo from "../icons/icon.png";
import {
  Text,
  SubHeader,
  SubmitButton,
  BigHeader,
  Link,
} from "../customComponents";
import Submit from "../inputs/Submit";
import LocationInput from "../inputs/LocationInput";
import Layout from "../elements/Layout";
import NumberInput from "../inputs/NumberInput";
import ImgCrop from "antd-img-crop";
import { Form, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { charLimitFormValidator, phoneValidator } from "../utils/validators";
import DateInput from "../inputs/DateInput";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    alert("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    alert("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default ({ cloudUtils, auth }) => {
  const { profileId } = useParams();
  const [profile, setProfile] = useState({ theme: "dark" });
  const [pic, setPic] = useState();
  const [handle, setHandle] = useState(false);
  const [taxLocation, setTaxLocation] = useState();
  const [address, setAddress] = useState();
  const [fireworks, setFireworks] = useState(true);
  const inputRef = useRef(null);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [imgLoad, setImgLoad] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setImgLoad(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setImgLoad(false);
        setImageUrl(url);
      });
    }
  };

  const [form] = Form.useForm();

  const picUpload = async (value) => {
    console.log("pic uploading");
    let picUrl =
      profileId +
      "/" +
      new Date().getTime() +
      "." +
      value.name.split(".").pop();
    await cloudUtils.uploadFileWithoutOriginFileObj(picUrl, value);
    setPic(picUrl);
  };

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, [profileId]);

  let createUser = cloudUtils.getFunction("createUser");

  const checkHandle = {
    validator: async () => {
      return (await cloudUtils.getDocumentB("profile", handle)).exists
        ? Promise.reject()
        : Promise.resolve();
    },
    message: "Handle is already taken!",
    required: true,
    validateTrigger: "onSubmit",
  };

  const acceptedChars = {
    validator: () =>
      /^[a-zA-Z0-9-]*$/.test(handle) ? Promise.resolve() : Promise.reject(),
    message: "Handle must only contain letters, numbers and hyphens!",
    required: true,
    validateTrigger: "onSubmit",
  };

  const profileComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        Let's get started by creating your unique handle. Pick something that
        really identifies your brand!
      </Text>
      <br />
      <TextInput
        onChange={(e) => setHandle(e.target.value)}
        label="handle"
        required={true}
        validators={[checkHandle, acceptedChars]}
        placeholder="Profile handle shared with customers"
      />

      <SubHeader style={{ fontWeight: "bold" }}>soco.market/{handle}</SubHeader>
      <Text>
        Share this link in your social media bio and with your customers!
      </Text>
    </div>
  );

  const picComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        Add your brand logo or best selfie! This will be the first thing your
        customers see on your SoCo home page.
      </Text>
      <br />

      <ImgCrop onModalOk={(e) => picUpload(e)} rotate>
        <Upload
          customRequest={({ onSuccess }) => onSuccess("ok")}
          className="avatar-uploader"
          accept="image/*,video/*,audio/*"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{ width: "100%", borderRadius: "100%" }}
            />
          ) : (
            <div>
              {imgLoad ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Add a Display Picture</div>
            </div>
          )}
        </Upload>
      </ImgCrop>
    </div>
  );

  const brandComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        Tell your customers who are you and what you offer. Add your registered
        trade name and describe what you do in a couple words.
      </Text>
      <br />
      <div align="left">
        <TextInput
          displayText="Trade Name"
          label="displayName"
          required={true}
          validators={[charLimitFormValidator("Trade Name", 30)]}
          placeholder="The name you advertise your business as"
        />
        <TextInput
          displayText="Profession"
          label="job"
          required={true}
          validators={[charLimitFormValidator("Profession", 30)]}
          placeholder="A short desciption of your service offering"
        />
      </div>
    </div>
  );

  const accountComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        Add your business' full legal name and address. This information is used
        to fill out your Social Contracts!{" "}
        <Link
          target="_blank"
          href="https://www.travelweekly.com/Mark-Pestronk/Whats-in-name-When-it-comes-to-contracts-potential-pitfall"
        >
          For more information on your legal business identity, click here.
        </Link>
      </Text>
      <br />

      <div align="left">
        <TextInput
          displayText="Legal Entity"
          label="name"
          required={true}
          initial={profile.name}
          placeholder="Your legal name, or legal name of your registered business"
        />
      </div>

      <LocationInput
        inputRef={inputRef}
        setTaxLocation={setTaxLocation}
        setAddress={setAddress}
      />
    </div>
  );

  const careerComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        Almost there! Add your phone number and date of birth so we can verify
        your identity and secure your account!
      </Text>
      <br />
      <div align="left">
        <NumberInput
          addonBefore="+1"
          displayText="Phone Number"
          label="phone"
          style={{ width: "100%" }}
          required={false}
          formatter={(value) => {
            let s = String(value);
            if (s.length > 6) {
              return `(${s.slice(0, 3)}) ${s.slice(3, 6)}-${s.slice(6, 10)}`;
            }
            if (s.length > 3) {
              return `(${s.slice(0, 3)}) ${s.slice(3, 6)}`;
            }
            if (s.length > 0) {
              return `(${s.slice(0, 3)}`;
            }
          }}
          validators={[phoneValidator]}
          parser={(value) => parseInt(value.replace(/\D/g, ""))}
        />

        <DateInput
          displayText="Date of Birth"
          label="dob"
          disableFunction={(date) => date > Date.now() - 220900000000}
        />
      </div>
      {/* <Submit big text="Done!" /> */}
    </div>
  );

  const title = [
    "Welcome to SoCo!",
    "Add your brand image!",
    "Tell us who you are!",
    "Legal identity",
    "Identity verification",
  ];

  const fields = [
    ["handle"],
    [],
    ["displayName", "job"],
    ["name"],
    ["phone", "dob"],
  ];

  if (current == 5) {
    return (
      <Layout
        page="Menu"
        loading={loading}
        theme="dark"
        style={{
          height: "100vh",
          display: "flex",
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <BigHeader> You're all set!</BigHeader>
        <br />
        <div>
          <SubHeader> Social Contracts </SubHeader>
          <Text>
            {" "}
            Use social contracts to book projects safely and systematically.
            We'll take care of your legal compliance and payments for you.
          </Text>
        </div>
        <br />
        <br />

        <div>
          <SubHeader> Links & Promotional Content </SubHeader>
          <Text>
            {" "}
            Add outbound links and promotional content for customers to view
            when they visit your page.
          </Text>
        </div>
        <br />
        <br />

        <div>
          <SubHeader> Payments (backed by Stripe) </SubHeader>
          <Text>
            {" "}
            Setup Stripe to start accepting card payments and Google Pay for
            your project contracts.
          </Text>
        </div>
        <br />
        <br />

        <div>
          <Submit big onClick={form.submit} text="Get Started" margin="0px" />
        </div>

        {fireworks && (
          <div class="pyro">
            <div class="before"></div>
            <div class="after"></div>
          </div>
        )}
      </Layout>
    );
  }

  return (
    <Layout
      title={title[current]}
      loading={loading}
      back={current > 0 ? () => setCurrent((cur) => cur - 1) : null}
      avatar={{ src: logo }}
    >
      <Form
        requiredMark={false}
        form={form}
        onFinish={async () => {
          profile.transactional = false;
          setLoading(true);
          createUser({
            ...profile,
            id: values.handle,
            dob: values.dob.format("MMM Do, YYYY"),
            phone: values.phone,
            location: address,
            taxLocation: taxLocation,
            pic,
            job: values.job,
            name: values.name,
            displayName: values.displayName,
            oldId: profileId,
          }).then(() => {
            window.location.href = `/${values.handle}/services`;
          });
        }}
        layout="vertical"
        style={{ paddingBottom: 48 }}
      >
        {profileComp(current == 0)}
        {picComp(current == 1)}
        {brandComp(current == 2)}
        {accountComp(current == 3)}
        {careerComp(current == 4)}
        <div
          style={{
            width: "100%",
            marginTop: 32,
            display: "flex",
            "justify-content": "space-between",
          }}
        >
          {current < 4 && (
            <SubmitButton
              onClick={() =>
                form
                  .validateFields(fields[current])
                  .then((values) => {
                    if (current == 3 && !taxLocation) {
                      alert("Please add your business address to continue");
                    } else {
                      setCurrent((cur) => cur + 1);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    alert(
                      "Please make sure all fields are filled out correctly!"
                    );
                  })
              }
              block
            >
              Next
            </SubmitButton>
          )}
          {current == 4 && (
            <SubmitButton
              block
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    setValues(form.getFieldsValue());
                    setTimeout(() => setFireworks(false), 2000);
                    setCurrent((cur) => cur + 1);
                  })
                  .catch(() =>
                    alert(
                      "Please make sure all fields are filled out correctly!"
                    )
                  );
              }}
            >
              Submit
            </SubmitButton>
          )}
        </div>
      </Form>
    </Layout>
  );
};
