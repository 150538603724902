import { Form, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { BlackLabel, Label } from "../customComponents";

const inputBoxStyle = {
  borderRadius: 12,
};

const BigTextInput = ({
  black = false,
  tight,
  displayText,
  label,
  required,
  lines,
  tooltip,
  style,
  initialValue = null,
  ...rest
}) => {

  const Lab = ({children}) => black ? <BlackLabel>{children}</BlackLabel> : <Label>{children}</Label>

  return (
    <Form.Item
      className={tight ? "tight" : null}
      initialValue={initialValue}
      labelCol={{ span: 24 }}
      label={
        displayText && (
          <Lab>
            {displayText}
            {tooltip && (
              <Tooltip placement="bottomLeft" title={tooltip}>
                <sup>
                  <InfoCircleOutlined style={{ fontSize: ".7rem" }} />
                </sup>
              </Tooltip>
            )}
          </Lab>
        )
      }
      name={label}
      rules={[
        {
          required,
          message: ``,
        },
      ]}
      style={style}
    >
      <Input.TextArea rows={lines || 4} {...rest} style={inputBoxStyle} />
    </Form.Item>
  );
};

export default BigTextInput;
