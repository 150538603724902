import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import Footer from "../elements/Footer";

export default ({ cloudUtils, profile, admin }) => {
  let navigate = useNavigate();

  const settingItem = {
    width: "100%",
    padding: "8px 0 8px 0",
    borderBottom: "1px solid",
  };

  const settingTitle = {
    "font-size": "24px",
    "line-height": "2.6rem",
    "text-align": "start",
  };

  const settingButton = {
    "text-align": "end",
  };

  return (
    <Row gutter={[0, 24]} >
      <Col span={24}>
        <div
          style={settingItem}
          onClick={() => navigate(`/${profile.id}/profile-settings`)}
        >
          <Row gutter={[0, 24]} justify="space-around" align="middle">
            <Col span={16} style={settingTitle}>
              Profile Settings
            </Col>
            <Col span={8} style={settingButton}>
              <RightOutlined style={{ fontSize: "1rem" }} />
            </Col>
          </Row>
        </div>
      </Col>

      <Col span={24}>
        <div
          style={settingItem}
          onClick={() => navigate(`/${profile.id}/settings`)}
        >
          <Row gutter={[0, 24]} justify="space-around" align="middle">
            <Col span={16} style={settingTitle}>
              Subscription Settings
            </Col>
            <Col span={8} style={settingButton}>
              <RightOutlined style={{ fontSize: "1rem" }} />
            </Col>
          </Row>
        </div>
      </Col>

      <Col span={24}>
        <div
          style={settingItem}
          onClick={() => navigate(`/${profile.id}/settings`)}
        >
          <Row gutter={[0, 24]} justify="space-around" align="middle">
            <Col span={16} style={settingTitle}>
              Rewards
            </Col>
            <Col span={8} style={settingButton}>
              <RightOutlined style={{ fontSize: "1rem" }} />
            </Col>
          </Row>
        </div>
      </Col>

      <Col span={24}>
        <div
          style={settingItem}
          onClick={() => navigate(`/${profile.id}/settings`)}
        >
          <Row gutter={[0, 24]} justify="space-around" align="middle">
            <Col span={16} style={settingTitle}>
              Support
            </Col>
            <Col span={8} style={settingButton}>
              <RightOutlined style={{ fontSize: "1rem" }} />
            </Col>
          </Row>
        </div>
      </Col>

      <Col span={24}>
        <Footer />
      </Col>
    </Row>
  );
};
