import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Layout from "../elements/Layout";
import SettingsForm from "../forms/SettingsForm";
import TipForm from "../forms/TipForm";
import SupportForm from "../forms/SupportForm";
import PurchaseForm from "../forms/PurchaseForm";
import Chat from "../elements/Chat";
import { SaveOutlined, LoadingOutlined, LogoutOutlined } from "@ant-design/icons";
import { PrimaryIcon, DangerGhostButton } from "../customComponents";

export default ({ cloudUtils, type, user, auth }) => {
  let { profileId } = useParams();
  const [save, setSave] = useState(true);
  const [spinner, startSpinner] = useState(false);

  const signOut = () => {
    auth().signOut()
    .then(() => {
      console.log('Signed Out');
      window.location = '//socoapp.ca'
    }).catch((error) => {
      console.error('Sign Out Error', error);
    });
  }

  let hb = spinner ? (
    [
      (<PrimaryIcon
        icon={<LoadingOutlined/>}
      />),
      (<DangerGhostButton
        icon={<LogoutOutlined />}
        onClick={signOut}
      />)
    ]
  ) : (
    [
      (<PrimaryIcon
        icon={<SaveOutlined/>}
        onClick={() => alert("Your changes are saved automatically")}
      />),
      (<DangerGhostButton
        icon={<LogoutOutlined />}
        onClick={signOut}
      />)
    ]
  );

  const navigate = useNavigate();

  const goHome = () => navigate(-1);

  const [profile, useProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    theme: "dark",
    links: [],
    transactional: false,
    authId: ":(",
    onboarding: true
  });

  let [loading, setLoading] = useState(true);
  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
    if (admin >= 0) {
      setLoading(false);
    }
  }, [profile]);
  const [pic, setPic] = useState(profile.pic);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, useProfile);
  }, []);

  useEffect(() => {
    if (profile.pic) {
      cloudUtils.getDownloadURL(profile.pic).then((src) => setPic(src));
    }
  }, [profile]);

  const pageMap = {
    settings: admin ? (
      <SettingsForm
        auth={auth}
        cloudUtils={cloudUtils}
        setLoading={setLoading}
        profile={profile}
        profileId={profileId}
        setSave={setSave}
        startSpinner={startSpinner}
      />
    ) : null,
    tip: (
      <TipForm
        cloudUtils={cloudUtils}
        setLoading={setLoading}
        profile={profile}
        profileId={profileId}
      />
    ),
    support: (
      <SupportForm
        cloudUtils={cloudUtils}
        setLoading={setLoading}
        profile={profile}
        profileId={profileId}
      />
    ),
    purchase: (
      <PurchaseForm
        cloudUtils={cloudUtils}
        setLoading={setLoading}
        profile={{ id: profileId, ...profile }}
      />
    ),
    chat: <Chat cloudUtils={cloudUtils} profile={profile} user={user} />,
  };

  const titleMap = {
    settings: "Settings",
    tip: `Tip ${profile.displayName}!`,
    support: "Ask us anything!",
    purchase: "Purchase Service",
    chat: `${profile.displayName}`,
  };

  const avatarMap = {
    settings: false,
    tip: true,
    support: false,
    purchase: false,
    chat: true,
  };

  const goBackMap = {
    settings: save
      ? goHome
      : () => alert("Please fill out all required fields"),
    purchase: () => navigate(-1),
    chat: () => navigate(-1),
  };

  return (
    <Layout
      type={type}
      admin={admin}
      headerButtons={type == "settings" ? hb : null}
      title={titleMap[type]}
      onBack={goBackMap[type] || goHome}
      loading={loading}
      theme={profile.theme}
      avatar={avatarMap[type] ? { src: pic } : null}
    >
      {pageMap[type]}
    </Layout>
  );
};

{
}
