import { Row, Form } from "antd";
import React, { useState } from "react";
import TextInput from "../inputs/TextInput";
import { emailValidator } from "../utils/validators";
import OrderCard from "../cards/OrderCard";
import Submit from "../inputs/Submit";
import Layout from "../elements/Layout";
import { useNavigate } from "react-router-dom";
import { LoginOutlined } from "@ant-design/icons";
import { Div, Text, OptionTag, Label, BigButton } from "../customComponents";
import BigTextInput from "../inputs/BigTextInput";
import { v4 as uuidv4 } from "uuid";
import Appointment from "../inputs/Appointment";
import moment from "moment";
import { serverTimestamp } from "firebase/firestore";

const ProjectView = ({ cloudUtils }) => {
  const [projects, setProject] = useState(false);

  const onFinish = async (raw) => {
    let { email, pin } = raw;
    cloudUtils.getDocument("customer", email, (d) => {
      if (d) {
        if (d.passcode == pin) {
          cloudUtils.getDocumentsBy("project", "email", email, setProject);
        }
      } else {
        alert("Your email or pin is incorrect!");
      }
    });
  };

  return !projects ? (
    <div
      style={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <Div align="center">
        <Text>
          Search for your orders by entering your email and order number. If
          you're a seller, please login above to track your projects.
        </Text>
        <br />
        <div>
          <Form
            requiredMark={false}
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <TextInput
              displayText="Email"
              label="email"
              required={true}
              validators={[emailValidator]}
            />
            <TextInput displayText="Pin" label="pin" required={true} />
            <Submit text="Submit" />
          </Form>
        </div>
        {/* <Info align="center" messages={['To track your orders as a seller, please login above']}/> */}
      </Div>
    </div>
  ) : (
    <Row gutter={[0, 24]}>
      {projects.map((data) => (
        <OrderCard
          customer={data.profile}
          status={data.status}
          desc={"Services Requested: " + data.services.length}
          time={data.time}
          price={data.price}
          onClick={() => window.open(`/${data.profile}/project/${data.id}`)}
          profile={{ theme: "#1b1e1f" }}
        />
      ))}
    </Row>
  );
};

const HireView = ({ cloudUtils, navigate }) => {
  const [profiles, setProfiles] = useState(false);
  let [day, setDay] = useState(moment("9:00", "HH:mm"));

  const onFinish = async (raw) => {
    let { buyerEmail, sellerEmail, inquiry, day } = raw;
    cloudUtils.updateDocument("invite", uuidv4(), {
      buyerEmail,
      sellerEmail,
      inquiry,
      day: day.valueOf(),
      created: Date.now(),
    });
  };

  let profile = profiles.pop();

  return profiles.length == 1 ? (
    <BigButton onClick={() => navigate(`/${profile.id}`)}>
      View {profile.name}'s Profile
    </BigButton>
  ) : (
    <div
      style={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <Div align="center">
        <Text>
          Search for your orders by entering your email and order number. If
          you're a seller, please login above to track your projects.
        </Text>
        <br />
        <div>
          <Form
            requiredMark={false}
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFieldsChange={({ sellerEmail }) => {
              cloudUtils.getDocumentsBy(
                "profile",
                "email",
                sellerEmail,
                setProfiles
              )
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <TextInput
              displayText="Email"
              label="email"
              required={true}
              validators={[emailValidator]}
            />

            <BigTextInput
              lines={8}
              displayText="Project Details"
              label="inquiry"
              required={true}
            />
            <Label>Meeting Time</Label>
            <Appointment
              day={day}
              setDay={setDay}
              duration={profile ? profile.duration : 60}
              openHours={profile ? profile?.openHours : ["09:00", "17:00"]}
              busy={profile ? profile.busy : []}
              closedDays={profile ? profile.closedDays : []}
            />

            <Submit text="Submit" />
          </Form>
        </div>
        {/* <Info align="center" messages={['To track your orders as a seller, please login above']}/> */}
      </Div>
    </div>
  );
};

export default ({ cloudUtils }) => {
  const [section, setSection] = useState("Contract a Freelancer");
  const navigate = useNavigate();

  return (
    <Layout
      title="Track your Order"
      headerButtons={[<LoginOutlined onClick={() => navigate("/login")} />]}
    >
      <div style={{ display: "flex", "justify-content": "space-evenly" }}>
        {["Contract a Freelancer", "Find your Project"].map((sec) => {
          return (
            <OptionTag
              big={true}
              selected={sec == section}
              onClick={() => {
                setSection(sec);
              }}
            >
              {sec}
            </OptionTag>
          );
        })}
      </div>
      <br />

      {section == "Find your Project" ? (
        <ProjectView cloudUtils={cloudUtils} />
      ) : (
        <HireView cloudUtils={cloudUtils} navigate={navigate} />
      )}
    </Layout>
  );
};
