import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../elements/Layout";
import ServiceList from "../lists/ServiceList";

export default ({ cloudUtils, user }) => {
  const { profileId } = useParams();
  let [admin, setAdmin] = useState();

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: {},
    theme: "dark",
  });

  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  const goBack = () =>
    navigate(`/${profileId}` + (profile.demo ? "/clauses" : ""));

  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
  }, [profile]);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
  }, [profileId]);

  return (
    <Layout title="Services" onBack={goBack} theme={profile.theme}>
      <ServiceList
        cloudUtils={cloudUtils}
        profile={{ id: profileId, ...profile }}
        templates={templates}
        admin={admin}
      />
    </Layout>
  );
};
