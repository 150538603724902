import { Rate, Form } from "antd";
import Carousel from "re-carousel";
import IndicatorDots from "../elements/IndictorDots";
import { useEffect, useState } from "react";
import TextInput from "../inputs/TextInput";
import Appointment from "../inputs/Appointment";
import UploadInput from "../inputs/UploadInput";
import { emailValidator } from "../utils/validators";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";

import moment from "moment";
import {
  BigButton,
  Divider,
  Text,
  SubmitButton,
  SubHeader,
  Div,
} from "../customComponents";
import Layout from "../elements/Layout";
import BigTextInput from "../inputs/BigTextInput";

const CarouselItem = ({ rating, comment, name }) => (
  <div align="center" style={{ overflow: "visible", height: "fit-content" }}>
    {rating && <Rate allowHalf disabled defaultValue={rating} />}
    {comment && <p>{comment}</p>}
    {name && (comment || rating) && (
      <p style={{ fontStyle: "italic" }}>{"   - " + name}</p>
    )}
  </div>
);

export default ({ cloudUtils, user }) => {
  const [current, setCurrent] = useState(0);
  const location = useLocation();
  const pages = [...location.state.services, "calendar", "details"];
  const viewId = pages[current];
  let [day, setDay] = useState(moment("9:00", "HH:mm"));

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { profileId } = useParams();
  const goBack = () => navigate(-1);
  const [projects, setProjects] = useState([]);
  const [templates, setTemplates] = useState({});
  const [profile, setProfile] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    location.state.services.forEach((tid) => {
      cloudUtils.getDocument("template", tid, (v) =>
        setTemplates((prev) => {
          prev[tid] = v;
          return { ...prev };
        })
      );
    });
  }, [location]);

  useEffect(() => {
    cloudUtils.getDocumentsBy("project", "profile", profileId, setProjects);
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, [profileId]);

  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
    if (admin >= 0) {
      setLoading(false);
    }
  }, [profile]);

  const reviewBox = {
    padding: "0px 8px 32px",
    flexGrow: 1,
    height: 200,
  };

  let displayedReviews = projects
    .filter(
      ({ status, rating, comment }) =>
        ["ACCEPTED", "COMPLETE"].includes(status) && (rating || comment)
    )
    .map(({ rating, comment }) => (
      <CarouselItem
        cloudUtils={cloudUtils}
        rating={rating}
        comment={comment}
        admin={admin}
        goBack={goBack}
      />
    ));

  const ServiceInput = ({ name, longDesc, survey, id }) => {
    return (
      <div style={{ display: id == viewId ? "initial" : "none" }}>
        <Div>
          <SubHeader>{name}</SubHeader>
          <Text break>{longDesc}</Text>
          <br />
          {Object.entries(survey || {}).map(([sid, { type, question }]) => {
            return type == "text" ? (
              <BigTextInput
                lines={4}
                label={`${id}.survey.${sid}`}
                displayText={question}
                required={true}
              />
            ) : (
              <UploadInput
                label={`${id}.survey.${sid}`}
                displayText={question}
                required={true}
              />
            );
          })}
          <BigTextInput
            label={`${id}.notes`}
            displayText="Questions & Comments"
            required={false}
          />
          <br />
        </Div>
      </div>
    );
  };

  let submitOffer = cloudUtils.getFunction("submitOffer");

  return (
    <Layout
      title="Book Consult"
      loading={loading}
      onBack={current > 0 ? () => setCurrent((cur) => cur - 1) : goBack}
      theme={profile.theme}
    >
      <div>
        <Form
          align="left"
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={async (values) => {
            setLoading(true);
            let { name, email, ...intake } = values;
            submitOffer({
              project: { name, email },
              profile: { id: profileId, ...profile },
              intake,
              services: location.state.services,
              serverTimestamp: serverTimestamp(),
              bookForClient: admin,
              day: day.valueOf(),
            }).then(({ data }) => {
              setLoading(false);
              if (data.link) {
                alert(
                  "Your discovery meeting time has been requested. You'll receive a meeting link in your email inbox once confirmed."
                );
                window.location.href = data.link;
              } else {
                alert(data.message);
              }
            });
          }}
        >
          {Object.entries(templates).map(([id, { survey, name, longDesc }]) => (
            <ServiceInput
              id={id}
              survey={survey}
              name={name}
              longDesc={longDesc}
            />
          ))}

          <div style={{ display: viewId == "details" ? "initial" : "none" }}>
            <Div>
              <TextInput label="name" displayText="Full Name" required={true} />
              <TextInput
                label="email"
                displayText="Email"
                required={true}
                validators={[emailValidator]}
              />
              <br />

              <BigButton block onClick={form.submit}>
                Book Now
              </BigButton>
              {displayedReviews.length ? (
                <>
                  <br />
                  <br />
                  <Divider displayText="Reviews" />
                  <div className="detailsTemplate">
                    <div style={reviewBox}>
                      <Carousel widgets={[IndicatorDots]}>
                        {displayedReviews}
                      </Carousel>
                    </div>
                  </div>
                </>
              ) : null}
            </Div>
          </div>
          <div style={{ display: viewId == "calendar" ? "initial" : "none" }}>
            <Div>
              <>
                <Text>
                  Book an appointment time for your free 60 minute consultation.
                  Together, we will go through your project requirements and
                  expectations.
                </Text>
                <br />
                {profile.openHours && (
                  <Appointment
                    day={day}
                    setDay={setDay}
                    duration={profile.duration || 60}
                    openHours={profile.openHours}
                    busy={profile.busy}
                    closedDays={profile.closedDays}
                  />
                )}
                <br />
              </>

              {profile.survey &&
                Object.entries(profile.survey).map(
                  ([sid, { type, question }]) => {
                    return type == "text" ? (
                      <TextInput
                        label={sid}
                        displayText={question}
                        required={true}
                      />
                    ) : (
                      <UploadInput
                        label={sid}
                        displayText={question}
                        required={true}
                      />
                    );
                  }
                )}
            </Div>
          </div>
        </Form>
        <br />
        {current < pages.length - 1 && (
          <SubmitButton block onClick={() => setCurrent((cur) => cur + 1)}>
            Next
          </SubmitButton>
        )}
      </div>
    </Layout>
  );
};
