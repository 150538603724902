import React, { useEffect, useState } from "react";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form, Checkbox } from "antd";
import TextInput from "../../inputs/TextInput";
import { DangerGhostButton, RegularButton } from "../../customComponents";
import Options from "../../forms/PackageSelect";
import ToggleInput from "../../inputs/ToggleInput";

function Quote({ bundles, form }) {
  const [bundleCount, setBundleCount] = useState(0);

  if (bundles && bundleCount != bundles.length) {
    setBundleCount(bundles.length);
  }

  useEffect(() => {
    const newQuote = (form.getFieldValue("quote") || []).map(
      ({ packages, id, unit, ...rest }) => {
        let newPackages = packages || [];
        if (packages) {
          newPackages = packages.filter((bundle) =>
            bundles.map(({ id }) => id).includes(bundle)
          );
        }
        return { packages: newPackages, ...rest };
      }
    );
    console.log(newQuote);
    form.setFieldsValue({ quote: newQuote });
  }, [bundleCount]);

  console.log(form.getFieldValue("quote"));
  return (
    <Form.List name="quote">
      {(fields, { add, remove }) => {
        console.log(fields);
        return (
          <div style={{ marginTop: 8 }}>
            {fields.map((field, index) => (
              <div key={field.key} style={{ marginBottom: 16 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextInput
                    tight
                    required={true}
                    placeholder="Item Description"
                    style={{ flexGrow: 1, marginBottom: 0, paddingRight: 16 }}
                    label={[index, "feature"]}
                  />
                  <TextInput
                    tight
                    required={true}
                    price={true}
                    placeholder="Price"
                    style={{ width: 90, marginBottom: 0, paddingRight: 16 }}
                    label={[index, "price"]}
                  />
                  <DangerGhostButton
                    onClick={() => remove(field.name)}
                    icon={<CloseCircleOutlined />}
                  />
                </div>
                <Options
                  placeholder="Appicable Scenarios"
                  bundles={bundles}
                  label={[index, "packages"]}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextInput
                    tight
                    price={true}
                    disabled={
                      form.getFieldValue(["quote", index, "count"]) === false
                    }
                    required={
                      form.getFieldValue(["quote", index, "count"]) === true
                    }
                    placeholder="Unit Cost"
                    style={{
                      flexGrow: 1,
                      marginBottom: 0,
                      paddingRight: 16,
                      marginTop: -26,
                    }}
                    label={[index, "unit"]}
                  />
                  <ToggleInput
                    label={[index, "count"]}
                    displayText={false}
                    checked="Units"
                    unChecked="Yes/No"
                    state={
                      form.getFieldValue(["quote", index, "count"]) === true
                    }
                    setState={() => {}}
                  />
                </div>
              </div>
            ))}
            <Form.Item>
              <center>
                <RegularButton onClick={add} icon={<PlusOutlined />}>
                  Add Cost
                </RegularButton>
              </center>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default Quote;
