import { Row } from "antd";
import React, { useEffect, useState } from "react";
import OrderCard from "../cards/OrderCard";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../elements/Layout";
import moment from "moment";
import { Label, SubHeader, SubmitButton, Text } from "../customComponents";

export default ({ cloudUtils }) => {
  const { profileId } = useParams();

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: {},
    theme: "dark",
  });

  const [projects, setProjects] = useState([]);
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  const goBack = () => navigate(`/${profileId}`);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocumentsBy("project", "profile", profileId, setProjects);
    cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
  }, [profileId]);

  return (
    <Layout title="Projects" onBack={goBack} theme={profile.theme}>
      <Row gutter={[0, 24]}>
        {projects.length ? (
          projects.map(
            ({
              id,
              name,
              email,
              discoveryStart,
              discoveryDuration,
              services,
              status,
              due,
              price,
            }) => {
              let startTime = moment(discoveryStart);
              let endTime = moment(discoveryStart).add(
                discoveryDuration,
                "minutes"
              );

              return (
                <OrderCard
                  customer={name}
                  desc={
                    <>
                      <Label>Customer Email</Label>
                      <Text> {email}</Text>
                      <br />
                      <Label>Requested Services</Label>
                      {services.map((tempid) => (
                        <Text>
                          {templates.filter(({ id }) => id == tempid)[0]?.name}
                        </Text>
                      ))}

                      {status == "LEAD" && (
                        <>
                          <br />
                          <Label>Appointment Time</Label>
                          <Text>
                            {moment(startTime).format("MMM Do YYYY, h:mm a")} to{" "}
                            {moment(endTime).format("h:mm a")}
                          </Text>
                        </>
                      )}
                    </>
                  }
                  price={price}
                  status={status}
                  onClick={() => navigate(`/${profileId}/projects/${id}`)}
                  key={"project" + id}
                  profile={profile}
                />
              );
            }
          )
        ) : (
          <center style={{ width: "100%" }}>
            {profile.demo && (
              <SubHeader>Congratulations, you're all set up! </SubHeader>
            )}

            <Text>
              Add{" "}
              <span style={{ color: "#ee9c65", fontWeight: "bold" }}>
                socoapp.ca/{profileId}
              </span>{" "}
              to your social media and website, or use our 'Handshake Deal' NFC
              stickers to start generating leads.
            </Text>
            {profile.demo && (
              <>
                <br />
                <Text>
                  Connect to Stripe to accept card, Apple and Google Pay.
                  Skipping this step will mean you can only to accept payments
                  outside of the platform.
                </Text>
                <br />
                <SubmitButton
                  onClick={() => navigate(`/${profileId}/profile-settings`)}
                >
                  Add Billing
                </SubmitButton>
              </>
            )}
          </center>
        )}
      </Row>
    </Layout>
  );
};
