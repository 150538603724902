import React, { useEffect, useState } from "react";
import daman from "../assets/daman.jpg";
import paige from "../assets/paige.jpg";
import stevan from "../assets/stevan.jpg";

function HeroCard() {
  const [cardState, setCardState] = useState(true);
  const [cardNumber, setCardNumber] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => changeState(), 3500);
    const interval2Id = setInterval(() => changeCount(), 7000);
    return () => {
      clearInterval(intervalId);
      clearInterval(interval2Id);
    };
  }, []);

  // Add a delay of 2 seconds before the interval starts running
  useEffect(() => {
    const timerId = setTimeout(() => {
      setCardNumber(0);
    }, 3500);

    // Clear timer when the component unmounts
    return () => clearTimeout(timerId);
  }, []);

  const CardImg = ({ src }: { src: any }) => (
    <img
      height={400}
      width={400}
      src={src}
      style={{
        height: "auto",
        width: "100%",
        borderRadius: "12px",
        clipPath:
          "polygon(25% 0%, 80% 0%, 100% 0%, 100% 75%, 75% 100%, 0% 100%, 0% 100%, 0% 25%)",
      }}
    />
  );
  interface cardDetails {
    name?: string;
    title?: string;
    image: React.ReactNode;
  }

  const cards: cardDetails[] = [
    {
      name: "Daman",
      title: "Web Developer",
      image: <CardImg src={daman} />,
    },
    {
      name: "Paige",
      title: "Graphic Designer",
      image: <CardImg src={paige} />,
    },
    {
      name: "Stevan",
      title: "Brand Strategist",
      image: <CardImg src={stevan} />,
    },
    // {
    //   image: (
    //     <div className="bg-SocoOutline bg-contain bg-no-repeat bg-center flex flex-col place-content-center items-center" style={{minWidth: "100%", height: 'inherit'}}>
    //       <h1 className="text-SocoWhite capitalize lg:text-xl">You</h1>
    //       <IoPersonCircleOutline className="fill-SocoOrangeLight h-16 w-16 " />
    //     </div>
    //   ),
    // },
  ];

  const animationTrigger = cardState
    ? "translate-y-0 opacity-100 lg:translate-x-0 lg:translate-y-0"
    : "translate-y-20 opacity-0 lg:translate-x-20 lg:translate-y-0";

  const changeCount = () => {
    setCardNumber((prevValue) => {
      return prevValue + 1 == cards.length ? 0 : prevValue + 1;
    });
  };

  const changeState = () => {
    setCardState((prevCardState) => !prevCardState);
  };

  return (
    <div className="my-5 w-full flex flex-row justify-evenly">
      {/* Card */}
      <div
        className={`transition-all duration-[1500ms] overflow-hidden w-[50vw] h-fit-content lg:w-64 lg:h-80 flex flex-col place-content-center items-center ${animationTrigger}`}
      >
        {/* Icon */}
        {cards[cardNumber].image}
        {/* Text */}
        <div style={{ width: "100%", textAlign: "left" }}>
          <p className="text-SocoWhite text-lg lg:text-xl mb-0 mt-3">
            {cards[cardNumber].name}
          </p>
          <p className="text-SocoWhite text-md lg:text-lg">
            {cards[cardNumber].title}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeroCard;
