export default {
  dark: {
    fontColor: "#E0E4E4",
    primary: "#E0E4E4",
    secondary: "#E0E4E4",
    accent: "#ee9c65",
    danger: "#d11a2a",
    background: "#1b1e1f",
    base: "#000000",
    design: "flat",
  },
  default: {
    fontColor: "#1b1e1f",
    primary: "#1b1e1f",
    secondary: "#E0E4E4",
    accent: "#F05E45",
    danger: "#d11a2a",
    background: "#FFFFFF",
    base: "#FFFFFF",
    design: "flat",
  },
  cool: {
    fontColor: "#253643",
    primary: "#253643",
    secondary: "#d8cd80",
    accent: "#8da88d",
    danger: "#d11a2a",
    background: "#fbfbfb",
    base: "#fbfbfb",
    design: "liquid",
  },
  coffee: {
    fontColor: "#2f2626",
    primary: "#2f2626",
    secondary: "#9E7676",
    accent: "#fffefd",
    danger: "#d11a2a",
    background: "#fffefd",
    base: "#fffefd",
    design: "block",
  },
  darkSquare: {
    fontColor: "#2b2b2b",
    primary: "#2b2b2b",
    secondary: "#b3b3b3",
    accent: "#fbfbfb",
    danger: "#d11a2a",
    background: "#fbfbfb",
    base: "#fbfbfb",
    design: "block",
  },
};
