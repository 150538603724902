import { useState, useEffect } from "react";
import TemplateForm from "../forms/TemplateForm";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Layout from "../elements/Layout";
import { Form } from "antd";
import qs from "query-string";

export default ({ cloudUtils }) => {
  let location = useLocation();
  const navigate = useNavigate();
  const { profileId } = useParams();
  const [profile, setProfile] = useState({ theme: "dark", socials: {} });
  const [templateId, _] = useState(uuidv4());
  const [form] = Form.useForm();

  let { service } = qs.parse(location.search);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, [profileId]);

  const goBack = () => navigate(-1);

  const [loading, setLoading] = useState(false);

  return (
    <Layout title="Add Service" onBack={goBack} theme={profile.theme}>
      <TemplateForm
        form={form}
        profile={profile}
        cloudUtils={cloudUtils}
        templateId={templateId}
        serviceId={service}
        setLoading={setLoading}
      />
    </Layout>
  );
};
