import { Form, TimePicker } from "antd";
import moment from "moment";
import { Label } from "../customComponents";

const DateRangeInput = ({
  displayText,
  label,
  defaultValue = [moment("9:00", "HH:mm"), moment("17:00", "HH:mm")],
  required = true,
  fieldSize,
  validators = [],
  ...rest
}) => {
  return (
    <Form.Item
      label={<Label>{displayText}</Label>}
      name={label}
      rules={[
        {
          required,
          message: `Please input a ${displayText}`,
        },
        ...validators,
      ]}
    >
      <TimePicker.RangePicker
        placeholder={["Opening Time", "Closing Time"]}
        style={{ borderRadius: 12 }}
        minuteStep={15}
        defaultValue={defaultValue}
        format="HH:mm"
      />
    </Form.Item>
  );
};

export default DateRangeInput;
