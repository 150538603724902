import { useEffect, useState } from "react";
import PictureCard from "../elements/PictureCard";
import { useNavigate } from "react-router-dom";
import { Row } from "antd";
import Options from "../forms/TagSelect";
import Layout from "../elements/Layout";

export default ({ cloudUtils }) => {
  const navigate = useNavigate();

  const [templates, setServices] = useState([]);
  const [serviceCards, setServiceCards] = useState({});
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    cloudUtils.getAllDocuments("template", setServices);
  }, []);

  useEffect(() => {
    const selectedSet = new Set(selected);
    setServiceCards({});
    Object.values(templates).forEach((data) => {
      if (
        !selected.length ||
        (data.tags || []).filter(Set.prototype.has, selectedSet).length > 0
      ) {
        cloudUtils.getUploadedSync(data.profile).then((src) => {
          setServiceCards((prev) => {
            return {
              ...prev,
              [data.profile]: (
                <PictureCard
                  title={data.profile}
                  desc={data.longDesc}
                  onClick={() => navigate(`/${data.profile}`)}
                  media={src}
                  profile={data.profile}
                />
              ),
            };
          });
        });
      }
    });
  }, [templates, selected]);

  return (
    <Layout
      title="Search Users"
      headerBody={<Options selected={selected} setSelected={setSelected} />}
    >
      <Row gutter={[0, 24]}>{Object.values(serviceCards)}</Row>
    </Layout>
  );
};
